<template>
  <div>
    <v-toolbar elevation="0">
      <div class="subtitle-1 font-weight-medium">
        {{ $t('contacts') }}
      </div>
      <v-spacer></v-spacer>
      <v-text-field
          placeholder="Search ..."
          outlined
          dense
          hide-details
          hide-spin-buttons
          clearable class="max-width-400"
      ></v-text-field>
<!--      <v-btn icon>-->
<!--        <v-icon>mdi-filter-variant</v-icon>-->
<!--      </v-btn>-->
    </v-toolbar>
    <v-container class="shah-max-app-with">
      <v-card
          elevation="0"
          class="mb-3 pa-lg-3"
          v-for="(contact, index) in getContacts"
          :key="index"
      >
        <v-card-text class="text--primary">
          <div class="align-center d-flex mb-4">
            <div class="mr-3" v-if="contact.imgUrl">
              <v-avatar>
                <img
                    :src="contact.imgUrl"
                    alt=""
                >
              </v-avatar>
            </div>
            <div>
              <div class="title">{{ contact.name }}</div>
              <div>{{ contact.title }}</div>
            </div>
            <v-spacer></v-spacer>
            <div class="align-self-start">
              <v-btn icon @click="$router.push({ name: 'ContactsUpdate', params: { id: contact.id }})">
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
            </div>
          </div>
          <v-row>
            <v-col>
              <div>
                <v-chip v-if="contact.instagram" class="ma-1" @click="copyContact(contact.instagram, 'Instagram')">
                  <v-icon class="mr-1">mdi-instagram</v-icon>
                  {{ contact.instagram }}
                </v-chip>
                <v-chip v-if="contact.twitter" class="ma-1" @click="copyContact(contact.twitter, 'Twitter')">
                  <v-icon class="mr-1">mdi-twitter</v-icon>
                  {{ contact.twitter }}
                </v-chip >
                <v-chip v-if="contact.facebook" class="ma-1" @click="copyContact(contact.facebook, 'Facebook')">
                  <v-icon class="mr-1">mdi-facebook</v-icon>
                  {{ contact.facebook }}
                </v-chip>
                <v-chip v-if="contact.phone" class="ma-1" @click="copyContact(contact.phone, 'Phone Number')">
                  <v-icon class="mr-1">mdi-phone</v-icon>
                  {{ contact.phone }}
                </v-chip>
                <v-chip v-if="contact.email" class="ma-1" @click="copyContact(contact.email, 'Email')">
                  <v-icon class="mr-1">mdi-email</v-icon>
                  {{ contact.email }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="getContacts.length === 0" elevation="0">
        <v-card-text class="text--primary">{{ $t('there-is-no-contacts') }}</v-card-text>
      </v-card>
    </v-container>
    <v-overlay opacity=".8" :value="overlay">
      <div class="align-center d-flex">
        <div class="mr-2" :class="{'title': $vuetify.breakpoint.smAndDown, 'display-1': $vuetify.breakpoint.mdAndUp}">
          {{ currentText }} Copied
        </div>
        <div>
          <v-icon large color="green">mdi-checkbox-marked-circle</v-icon>
        </div>

      </div>
    </v-overlay>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data(){
    return {
      overlay: false,
      currentText: ''
    }
  },
  methods: {
    copyContact(material, type){
      this.overlay = true;
      this.currentText = type;
      navigator.clipboard.writeText(material);
      setTimeout(() => {
        this.overlay = false;
      }, 700)
    }
  },
  computed: {
    ...mapGetters([
        'getContacts'
    ])
  }
}
</script>

<style scoped>
.max-width-400{
  max-width: 400px;
}
</style>