<template>
  <div>
    <v-toolbar elevation="0">
      <v-btn icon @click="$router.push({ name: 'Demonstrations' })" class="mr-4">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="subtitle-1 font-weight-medium">
        {{ existingDocument ? 'Update demonstration' : 'Add new demonstration' }}
      </span>
      <v-spacer v-if="existingDocument"></v-spacer>
      <v-btn icon color="indigo darken-2" v-if="existingDocument" class="mr-1" @click="submit()">
        <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn icon color="indigo darken-2" v-if="existingDocument" class="mr-1"
             @click="$router.push({ name: 'DemonstrationsItem', params:{ id: existingDocument.id } })">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn icon color="indigo darken-2" v-if="existingDocument" class="mr-1" @click="console.log('archive')">
        <v-icon>mdi-archive-arrow-down-outline</v-icon>
      </v-btn>
      <v-btn icon color="red darken-2" v-if="existingDocument" @click="areYouSureDialog = true">
        <v-icon>mdi-delete</v-icon>
      </v-btn>

    </v-toolbar>

    <v-container class="shah-max-app-with">
      <v-row>
        <v-col cols="12">
          <v-card elevation="0">
            <v-card-text class="text--primary pa-lg-8">
              <v-form ref="form"
                      v-model="valid"
                      lazy-validation>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-col cols="12" class="mb-3">
                      <v-card outlined style="background-color: #f6f6f6;"
                              class="mb-2 d-flex align-center justify-center" @click="click1">
                        <div style="position: absolute">
                          <div v-if="!previewImageExists && !imgUrl" class="mt-1">
                            <v-icon class="mr-2" small style="margin-top: -2px">mdi-paperclip</v-icon>
                            <span class="subtitle-2">Choose photo</span>
                          </div>

                          <input type="file" ref="input1"
                                 style="display: none"
                                 @change="previewImage" accept="image/*">
                        </div>
                        <div>
                          <img id="output" height="150" style="padding-top: 10px;padding-bottom: 2px"/>
                          <img v-if="imgUrl && !previewImageExists" :src="imgUrl" height="150" style="padding-top: 10px;padding-bottom: 2px" />
                        </div>
                      </v-card>
                    </v-col>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Title" required outlined counter
                        :rules="titleRule"
                        v-model="titleModel"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Place" required outlined
                        :rules="placeRule"
                        v-model="placeModel"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Date" required outlined
                        :rules="dateRule"
                        v-model="dateModel"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Time" required outlined
                        :rules="timeRule"
                        v-model="timeModel"
                    ></v-text-field>
                  </v-col>

                  <!--                  <v-row justify="center" class="my-3 pa-3">-->
                  <!--                    <v-date-picker v-model="picker"></v-date-picker>-->
                  <!--                  </v-row>-->

                  <v-col cols="12">
                    <v-textarea
                        label="Description" rows="1" required outlined counter auto-grow
                        :rules="descriptionRule"
                        v-model="descriptionModel"
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="ManifestSource" required outlined
                        :rules="manifestSourceRule"
                        v-model="manifestSourceModel"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="mb-4" v-if="!existingDocument">
                    <v-btn block large depressed dark color="orange darken--4" @click="submit()">
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="areYouSureDialog"
        width="500"
        v-if="existingDocument"
    >
      <v-card class="pa-4">
        <v-container class="pa-4">
          <v-row class="text-center">
            <v-col cols="12">
              Are you sure you want to delete?
            </v-col>
            <v-col cols="12">
              <v-btn depressed @click="areYouSureDialog = false" class="mr-4">Cancel</v-btn>
              <v-btn depressed dark color="red" @click="deleteDemonstrationClicked()">Delete</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data: () => ({
    valid: true,
    picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    titleModel: '',
    titleRule: [
      v => !!v || 'Title is required',
      v => (v && v.length <= 30) || 'Max 30 character',
    ],
    descriptionModel: '',
    descriptionRule: [
      v => !!v || 'Description is required',
      v => (v && v.length <= 350) || 'Description must be less than 350 characters',
    ],
    placeModel: '',
    placeRule: [
      v => !!v || 'Place is required'
    ],
    dateModel: '',
    dateRule: [
      v => !!v || 'Date is required'
    ],
    timeModel: '',
    timeRule: [
      v => !!v || 'Time is required'
    ],
    manifestSourceModel: '',
    manifestSourceRule: [
      v => !!v || 'Manifest source is required'
    ],
    existingDocument: null,
    areYouSureDialog: false,
    previewImageExists: false,
    imgUrl: '',
    imageData: null
  }),
  mounted() {
    if (this.$route.name === 'DemonstrationsUpdate') {
      this.existingDocument = this.getDocumentById({id: this.$route.params.id, collectionName: 'demonstrations'});
      if(this.existingDocument){
        this.titleModel = this.existingDocument.title;
        this.descriptionModel = this.existingDocument.description;
        this.placeModel = this.existingDocument.place;
        this.dateModel = this.existingDocument.date;
        this.timeModel = this.existingDocument.time;
        this.manifestSourceModel = this.existingDocument.manifestSource;
        this.imgUrl = this.existingDocument.imgUrl;
      }
    }
  },
  methods: {
    ...mapActions([
      'addItem',
      'updateItem',
      'deleteItem'
    ]),
    async submit() {
      this.valid = await this.$refs.form.validate()
      if (this.valid) {
        const models = {
          title: this.titleModel,
          description: this.descriptionModel,
          place: this.placeModel,
          date: this.dateModel,
          time: this.timeModel,
          manifestSource: this.manifestSourceModel,
          imgUrl: this.imageData ? this.imageData : this.imgUrl ? this.imgUrl : null
        }
        if (this.existingDocument) {
          this.updateItem({
            models: {...models, id: this.existingDocument.id},
            collectionName: 'demonstrations'
          }).then(() => {
            this.$refs.form.reset()
            this.$router.push({name: 'DemonstrationsItem', params: {id: this.existingDocument.id}})
          }).catch(error => {
            console.log(error)
          })
        } else {
          this.addItem({models: models, collectionName: 'demonstrations'}).then(() => {
            this.$router.push({name: 'Demonstrations'})
          }).catch(error => {
            console.log(error)
          })
        }
      } else {
        console.log('form not valid')
      }


    },
    deleteDemonstrationClicked() {
      this.deleteItem({collectionName: 'demonstrations', id: this.existingDocument.id}).then(() => {
        this.$router.push({name: 'Demonstrations'})
      }).catch(error => {
        console.log(error)
      })
    },
    click1() {
      this.$refs.input1.click()
    },
    previewImage(event) {
      this.imgUrl = null;
      this.imageData = event.target.files[0];
      let output = document.getElementById('output');
      output.src = URL.createObjectURL(event.target.files[0]);
      output.onload = function () {
        URL.revokeObjectURL(output.src) // free memory
      }
      this.previewImageExists = true
      // this.onUpload();
    },
  },
  computed: {
    ...mapGetters([
      'getDocumentById'
    ])
  }
}
</script>

<style scoped>
.block-badges {
  display: block !important;
}

.input-lable-icons {
  margin-top: -4px;
  margin-left: 3px
}
</style>