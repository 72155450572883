// const rayisJomhoorayeEu = [
//   { name: 'Charles Michel', role: 'President of the European Council', twitterId: '@charlesmichel', country:'be' },
//   { name: 'Karl Nehammer', role: 'Federal Chancellor', twitterId: '@karlnehammer', country:'at' },
//   { name: 'Alexander De Croo', role: 'Prime Minister', twitterId: '@alexanderdecroo', country:'be' },
//   { name: 'Rumen Radev', role: 'President of the Republic', twitterId: '@PresidentOfBg', country:'bg' },
//   { name: 'Andrej Plenković', role: 'Prime Minister', twitterId: '@AndrejPlenkovic', country:'hr' },
//   { name: 'Nicos Anastasiades', role: 'President of the Republic', twitterId: '@PresidentOfBg', country:'cy' },
//   { name: 'Petr Fiala', role: 'Prime Minister', twitterId: '@P_Fiala', country:'cz' },
//   { name: 'Mette Frederiksen', role: 'Prime Minister', twitterId: '@Statsmin', country:'dk' },
//   { name: 'Kaja Kallas', role: 'Prime Minister', twitterId: '@kajakallas', country:'ee' },
//   { name: 'Sanna Marin', role: 'Prime Minister', twitterId: '@MarinSanna', country:'fi' },
//   { name: 'Emmanuel Macron', role: 'President of the Republic', twitterId: '@emmanuelmacron', country:'fr' },
//   { name: 'Olaf Scholz', role: 'Federal Chancellor', twitterId: '@bundeskanzler', country:'de' },
//   { name: 'Kyriakos Mitsotakis', role: 'Prime Minister', twitterId: '@primeministerGR', country:'gr' },
//   { name: 'Viktor Orbán', role: 'Prime Minister', twitterId: '@PM_ViktorOrban', country:'hu' },
//   { name: 'Leo Varadkar', role: 'The Taoiseach', twitterId: '@LeoVaradkar', country:'ie' },
//   { name: 'Giorgia Meloni', role: 'Prime Minister', twitterId: '@Palazzo_Chigi', country:'it' },
//   { name: 'Krišjānis Kariņš', role: 'Prime Minister', twitterId: '@karinskrisjanis', country:'lv' },
//   { name: 'Gitanas Nausėda', role: 'President of the Republic', twitterId: '@gitanasnauseda', country:'lt' },
//   { name: 'Xavier Bettel', role: 'Prime Minister', twitterId: '@Xavier_Bettel', country:'lu' },
//   { name: 'Robert Abela', role: 'Prime Minister', twitterId: '@RobertAbela_MT', country:'mt' },
//   { name: 'Mark Rutte', role: 'Prime Minister', twitterId: '@MinPres', country:'nl' },
//   { name: 'Mateusz Morawiecki', role: 'Prime Minister', twitterId: '@MorawieckiM', country:'pl' },
//   { name: 'António Costa', role: 'Prime Minister', twitterId: '@antoniocostapm', country:'pt' },
//   { name: 'Klaus Werner Iohannis', role: 'President', twitterId: '@@KlausIohannis', country:'ro' },
//   { name: 'Eduard Heger', role: 'Prime Minister', twitterId: '@eduardheger', country:'sk' },
//   { name: 'Robert Golob', role: 'Prime Minister', twitterId: '@govSlovenia', country:'si' },
//   { name: 'Pedro Sánchez', role: 'Prime Minister', twitterId: '@sanchezcastejon', country:'es' },
//   { name: 'Ulf Kristersson', role: 'Prime Minister', twitterId: '@SwedishPM', country:'se' },
//   { name: 'Ursula von der Leyen', role: 'President of the European Commission', twitterId: '@vonderleyen', country:'be' }
// ]
// { name: 'Ioannis Vrailas', role: 'Permanent representative', twitterId: '@vrailas', country:'gr' },
const mentions1 = [
  { name: 'Josep Borrell Fontelles', role: 'High Representative of the EU for Foreign Affairs and Security', twitterId: '@JosepBorrellF', country:'es' },
  { name: 'Hadja Lahbib', role: 'Minister for foreign affairs', twitterId: '@hadjalahbib', country:'be' },
  { name: 'Nikolay Milkov', role: 'Minister for foreign affairs', twitterId: '@MFABulgaria', country:'bg' },
  { name: 'Lars Løkke Rasmussen', role: 'Minister for foreign affairs', twitterId: '@larsloekke', country:'dk' },
  { name: 'Annalena Baerbock', role: 'Federal Minister for foreign affairs', twitterId: '@ABaerbock', country:'de' },
  { name: 'Urmas Reinsalu', role: ' Minister for foreign affairs', twitterId: '@UrmasReinsalu', country:'ee' },
  { name: 'Micheál Martin', role: 'Minister for foreign affairs', twitterId: '@MichealMartinTD', country:'ie' },
  { name: 'José Manuel Albares', role: 'Minister for foreign affairs', twitterId: '@jmalbares', country:'es' },
  { name: 'Catherine Colonna', role: 'Minister for foreign affairs', twitterId: '@MinColonna', country:'fr' },
  { name: 'Gordan Grlić Radman', role: 'Minister for foreign affairs', twitterId: '@grlicradman', country:'hr' },
  { name: 'Ioannis Kasoulides', role: 'Minister for foreign affairs', twitterId: '@IKasoulides', country:'cy' },
  { name: 'Antonio Tajani', role: 'Minister for foreign affairs', twitterId: '@Antonio_Tajani', country:'it' },
  { name: 'Edgars Rinkēvičs', role: 'Minister for foreign affairs', twitterId: '@edgarsrinkevics', country:'lv' },
  { name: 'Gabrielius Landsbergis', role: 'Minister for foreign affairs', twitterId: '@GLandsbergis', country:'lt' },
  { name: 'Jean Asselborn', role: 'Minister for foreign affairs', twitterId: '@MFA_Lu', country:'lu' },
  { name: 'Ian Borg', role: 'Minister for foreign affairs', twitterId: '@MinisterIanBorg', country:'mt' },
  { name: 'Wopke Hoekstra', role: 'Minister for foreign affairs', twitterId: '@WBHoekstra', country:'nl' },
  { name: 'Alexander Schallenberg', role: 'Minister for foreign affairs', twitterId: '@a_schallenberg', country:'at' },
  { name: 'João Cravinho', role: 'Minister for foreign affairs', twitterId: '@JoaoCravinho', country:'pt' },
  { name: 'Bogdan Aurescu', role: 'Minister for foreign affairs', twitterId: '@BogdanAurescu', country:'ro' },
  { name: 'Rastislav Káčer', role: 'Minister for foreign affairs', twitterId: '@RastislavKacer', country:'sk' },
  { name: 'Pekka Haavisto', role: 'Minister for foreign affairs', twitterId: '@Haavisto', country:'fi' },
  { name: 'Tobias Billström', role: 'Minister for foreign affairs', twitterId: '@TobiasBillstrom', country:'se' },
  { name: 'Zbigniew Rau', role: 'Minister for foreign affairs', twitterId: '@RauZbigniew', country:'pl' },
  { name: 'Tanja Fajon', role: 'Minister for foreign affairs', twitterId: '@tfajon', country:'si' },
]
export { mentions1 }