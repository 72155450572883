<template>
  <v-bottom-navigation
      :value="value"
      color="indigo darken-2"
      app
      class="bottom-navigation-border"
  >
    <v-btn v-for="(btn, index) in navButtons" :key="index" @click="pushToPath(btn)">
      <span :class="btn.labelClass">{{ $t(btn.label) }}</span>
      <v-icon>{{ btn.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data: () => ({
    navButtons: [
      // {id: 0, label: 'report', icon: 'mdi-flag', labelClass: 'mt-1 mb-1', pathName: 'Reporting'},
      // {id: 0, label: 'contacts', icon: 'mdi-text-box', labelClass: 'mt-1 mb-1', pathName: 'Contacts'},
      {id: 0, label: 'current-actions', icon: 'mdi-hand-peace', labelClass: 'mt-1 mb-1', pathName: 'CurrentActions'},
      {id: 1, label: 'demonstrations', icon: 'mdi-account-group', labelClass: 'mt-1 mb-1', pathName: 'Demonstrations'},
    ],
    value: 0
  }),
  created() {
    const mongol = this.navButtons.find(button => button.pathName === this.$route.name);
    if (mongol){
      this.value = mongol.id;
    } else {
      this.value = 0;
    }
  },
  methods: {
    pushToPath(btn) {
      this.$router.push({name: btn.pathName}).catch(() => {})
    },
  },
}
</script>

<style scoped>
.v-bottom-navigation {
  height: 60px !important;
  box-shadow: none;
}

.bottom-navigation-border{
  border-top: 1px solid #f1f1f1;
}
</style>