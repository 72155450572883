<template>
  <div>
    <!--    <div style="text-align: center; margin-bottom: -10px;">-->
    <!--      <span style="padding: 5px 10px 5px 10px;;z-index: 999; margin-bottom: -30px; border-radius: 10px;color:white; font-size: 12px; background-color: darkred">Must-Win-Battle</span>-->
    <!--    </div>-->
    <v-toolbar elevation="0">
      <div class="subtitle-1 font-weight-medium">
        <!--        {{ $t('current-actions') }}-->
        Actions
      </div>
      <v-spacer></v-spacer>
      <v-btn depressed class="none-text-transform font-weight-regular" @click="openFullscreen()">
        First time here? Watch this
        <v-icon class="ml-2">mdi-youtube</v-icon>
      </v-btn>
      <!--      <v-btn icon>-->
      <!--        <v-icon>mdi-filter-variant</v-icon>-->
      <!--      </v-btn>-->
    </v-toolbar>
    <v-container class="shah-max-app-with">
      <!--      <div class="text-center my-5">-->
      <!--        <v-btn depressed color="primary" @click="firstTimeVisitModal = true" class="mr-4 none-text-transform">Första gången här?</v-btn>-->
      <!--      </div>-->
      <!--      <v-card class="mb-3 pa-lg-3" elevation="0">-->
      <!--        <v-card-text class="text&#45;&#45;primary">-->
      <!--          -->
      <!--        </v-card-text>-->
      <!--      </v-card>-->
      <!--      <v-card-->
      <!--          elevation="0"-->
      <!--          class="mb-3 pa-lg-3"-->
      <!--      >-->
      <!--        <v-card-text class="text&#45;&#45;primary">-->

      <!--        </v-card-text>-->
      <!--      </v-card>-->

      <v-card
          elevation="0"
          class="mb-3 pa-lg-3"
          v-for="(currentAction, index) in filteredCurrensActions"
          :key="index"
          v-if="currentAction.status === 'live'"
          @click="openCurrentAction(currentAction)"
      >
        <v-card-text class="text--primary">
          <v-row class="align-center">
            <v-col cols="11">
              <div class="deep-orange--text text--darken-3 font-weight-medium shah-three-dots-text mb-1">
                {{ currentAction.title }}
              </div>
              <div>
                {{ currentAction.event }}
              </div>
            </v-col>
            <v-col cols="1" class="text-center pa-0">
              <v-icon>mdi-chevron-right</v-icon>
            </v-col>
          </v-row>
          <v-divider class="mt-4"></v-divider>
          <v-row no-gutters class="mt-4 justify-space-between align-center">
            <v-col v-if="numberOfReactions(currentAction)">
              {{ numberOfReactions(currentAction) }} acts
              <!--              <v-btn style="text-transform: none; font-size: 13px" small depressed @click.stop="openCurrentSogand(currentAction)">-->
              <!--                {{ numberOfReactions(currentAction) }} acts-->
              <!--              </v-btn>-->
              <!--              {{ $t('persons-have-reacted') }}-->
              <!--              <v-icon>mdi-hand-peace</v-icon>-->
              <!--              {{ currentAction.reactions || 0 }} visningar-->
              <!--              <v-icon color="grey" v-if="currentAction.twitterLink">mdi-twitter</v-icon>-->
              <!--              <v-icon color="grey" v-if="currentAction.email">mdi-phone</v-icon>-->
              <!--              <v-icon color="grey" v-if="currentAction.phone">mdi-email</v-icon>-->
            </v-col>
            <v-col v-else>
              {{ $t('no-reactions-yet') }}
            </v-col>
            <v-col class="text-right deep-orange--text text--darken-3 first-letter-upper-case">
              {{ getPassedTime(currentAction) }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="!liveDemonstrationsExist" elevation="0">
        <!--      <v-card v-if="true" elevation="0">-->
        <v-card-text class="text--primary">{{ $t('there-is-no-actions') }}</v-card-text>
      </v-card>
    </v-container>
    <video controls autoplay id="sogand-video-2" style="width: 0;">
      <source src="../../../src/assets/video/sogand.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
<!--    <v-dialog-->
<!--        persistent-->
<!--        v-model="firstTimeVisitModal"-->
<!--        width="500"-->
<!--    >-->
<!--      <v-btn @click="closeFirstTimeDialogs()" fab small absolute style="margin-left:-17px; margin-top: -25px" depressed>-->
<!--        <v-icon>mdi-close</v-icon>-->
<!--      </v-btn>-->
<!--      <video controls autoplay id="sogand-video-2">-->
<!--        <source src="../../../src/assets/video/sogand.mp4" type="video/mp4">-->
<!--        Your browser does not support the video tag.-->
<!--      </video>-->
<!--    </v-dialog>-->
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  data() {
    return {
      firstTimeVisitModal: false
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentActions',
      'getCurrentCountry',
      'getReactionsById'
    ]),
    showActions() {
      return this.getCurrentActions.length > 1;
    },
    liveDemonstrationsExist() {
      return this.getCurrentActions.some(demo => demo.status === 'live')
    },
    filteredCurrensActions() {
      return this.getCurrentActions.sort((a, b) => {
        return b.dateCreated - a.dateCreated
      })
    }
  },
  mounted() {
    const elem = document.getElementById("sogand-video-2");
    elem.addEventListener(
        'fullscreenchange',
        function(event) {
          if (!document.fullscreenElement) {
            elem.pause();
            elem.style.display = 'none';
          }
        },
        false
    );
  },
  methods: {
    ...mapActions([
      'updateReactionCounter'
    ]),
    numberOfReactions(currentAction) {
      // const react = this.getReactionsById(currentAction.currentTwitterLink.split('/')[5]);
      if (this.getReactionsById(currentAction.id)) {
        const react = this.getReactionsById(currentAction.id);
        // loggg('react')
        // loggg(react)
        return react.total_tweet_count;
      } else {
        return ''
      }

    },
    openCurrentAction(currentAction) {
      this.$router.push('/' + this.getCurrentCountry.abbreviation + '/current-actions/' + currentAction.id);
      if (isProduction) this.updateReactionCounter(currentAction);
    },
    getPassedTime(currentAction) {
      if (currentAction.dateCreated) {
        // console.log(moment.locale(this.getCurrentCountry.abbreviation))
        return moment.duration(moment().diff(currentAction.dateCreated.toDate())).humanize() + " " + this.$t('ago');
      } else {
        return 'unknown';
      }
    },
    openCurrentSogand(currentAction) {
      let yolo = "https://twitter.com/hashtag/Sogand19?src=hashtag_click";
      if (window.screen.width > 550) {
        window.open(yolo, '_blank');
      } else {
        window.open(yolo, "_self");
      }
      // loggg(currentAction)
    },
    playPausedClicked() {
      loggg('asdasdasd')
    },
    closeFirstTimeDialogs() {
      this.firstTimeVisitModal = false;
      const videoPlayer = document.getElementById('sogand-video-2');
      videoPlayer.pause();
    },
    openFirstTimeDialog(){
      this.firstTimeVisitModal = true;
      const video = document.getElementById('sogand-video-2')
      video.requestFullscreen()
    },
    openFullscreen(){
      const elem = document.getElementById("sogand-video-2");
      elem.style.display = 'flex';
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
      elem.play();
    }
  }
}
</script>

<style scoped>
.shah-three-dots-text {
  font-size: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;;
  white-space: nowrap;
}

#sogand-video {

}


</style>