import Vue from 'vue'
import VueI18n from "vue-i18n";

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  silentFallbackWarn: true,
  messages: {
    en: {
      "storms": "Storms",
      "report": 'Report',
      "current-actions": 'Current Actions',
      "contacts": 'Contacts',
      "there-is-no-actions": 'There is no actions.',
      'event': 'Event',
      'demonstrations': 'Demonstrations',
      'your-rights': 'Your rights',
      'action': 'Action',
      'there-is-no-contacts': 'There is no contacts.',
      'there-is-no-reports': 'There is no one to report.',
      'there-is-no-demonstrations': 'There is no demonstration planned.',
      'take-action-on-twitter': 'Take action on Twitter',
      'source': 'Source',
      'right-now': 'Right now',
      'what-can-i-do': 'What can I do?',
      'persons-have-reacted': 'people have reacted',
      'no-reactions-yet': 'No reactions yet',
      'ago': 'ago',
      '': '',
    },
    sv: {
      "report": 'Rapportera',
      "current-actions": 'Aktuella handlingar',
      "contacts": 'Kontakter',
      "there-is-no-actions": 'Det finns inga handlingar just nu.',
      'demonstrations': 'Demonstrationer',
      'event': 'Händelse',
      'your-rights': 'Dina rättigheter',
      'action': 'Handling',
      'there-is-no-contacts': 'Det finns inga kontakter.',
      'there-is-no-reports': 'Det finns ingen att rapportera.',
      'there-is-no-demonstrations': 'Det finns inga planerade demonstrationer.',
      'take-action-on-twitter': 'Agera på Twitter',
      'source': 'Källa',
      'right-now': 'Aktuellt just nu',
      'what-can-i-do': 'Vad kan jag göra?',
      'persons-have-reacted': 'personer har reagerat',
      'no-reactions-yet': 'Inga reaktioner än.',
      'ago': 'sen',
    },
    fa: {
      "storms": "طوفان ها"
    }
  }
})

export default i18n;