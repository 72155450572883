<template>
  <div v-if="getIsAutenticated">
    <v-toolbar elevation="0">
      <!--      <v-btn icon @click="$router.push({ name: 'ActionItem', params:{ id: existingDocument.id } })" class="mr-4">-->
      <v-btn icon @click="$router.push({ name: 'CurrentActions' })" class="mr-4">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="subtitle-1 font-weight-medium">
        {{ existingDocument ? 'Update action' : 'Add new action' }}
      </span>
      <v-spacer v-if="existingDocument"></v-spacer>
      <v-btn icon color="indigo darken-2" v-if="existingDocument" class="mr-1" @click="submit()">
        <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn icon color="indigo darken-2" v-if="existingDocument" class="mr-1"
             @click="$router.push({ name: 'CurrentActionsItem', params:{ id: existingDocument.id } })">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn icon color="indigo darken-2" v-if="existingDocument" class="mr-1" @click="archiveItemClicked()">
        <v-icon>mdi-archive-arrow-down-outline</v-icon>
      </v-btn>
      <v-btn icon color="red darken-2" v-if="existingDocument" @click="areYouSureDialog = true">
        <v-icon>mdi-delete</v-icon>
      </v-btn>

    </v-toolbar>

    <v-container class="shah-max-app-with">
      <v-row>
        <v-col cols="12">
          <v-card elevation="0">
            <v-card-text class="text--primary pa-lg-8">
              <v-form ref="form"
                      v-model="valid"
                      lazy-validation>
                <v-row no-gutters>
                  <v-flex xs12>
                    <v-text-field outlined counter label="Title *"
                                  :rules="titleRule"
                                  v-model="titleModel"
                    ></v-text-field>
                    <!--                    <v-textarea counter outlined auto-grow rows="1"-->
                    <!--                                label="Action *"-->
                    <!--                                :rules="actionRule"-->
                    <!--                                v-model="actionModel"-->

                    <!--                    ></v-textarea>-->
                    <v-textarea rows="1" outlined auto-grow counter
                                label="Event"
                                :rules="eventRule"
                                v-model="eventModel"
                    >
                    </v-textarea>
                    <!--                    <v-textarea outlined auto-grow rows="1" counter-->
                    <!--                                label="Your rights"-->
                    <!--                                v-model="yourRightsModel"-->
                    <!--                    ></v-textarea>-->

                    <!-- TODO:    Tabs start -->

                    <v-tabs v-model="tab" color="orange darken-3"
                            grow>
                      <v-tab>
                        <v-icon class="mr-2">mdi-twitter</v-icon>
                        Twitter
                      </v-tab>
                      <v-tab>
                        <v-icon class="mr-2">mdi-phone</v-icon>
                        Phone
                      </v-tab>
                      <!--                      <v-tab>Email</v-tab>-->

                      <v-tabs-items v-model="tab" class="pt-5">
                        <v-tab-item>
                          <v-row no-gutters v-for="(twitterLinkModel, index) in twitterLinksModel" :key="index">
                            <v-col cols="11">
                              <v-text-field
                                  outlined
                                  hide-details
                                  class="mb-3"
                                  label="Tweet URL"
                                  v-model="twitterLinkModel.model"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="1">
                              <v-btn @click="removeTwitterLink(index)" v-if="index > 0" class="mt-2 justify-end" icon
                                     depressed color="orange darken-3">
                                <v-icon>mdi-minus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col cols="12">
                              <v-btn @click="addTwitterLink()" class="mb-8" small icon depressed
                                     color="orange darken-3">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>

                          <v-combobox
                              v-model="hashtagsModel"
                              :items="hashtagsItems"
                              chips
                              label="Hashtags"
                              multiple
                              outlined
                              hide-selected
                          >
                            <template v-slot:selection="{ attrs, item, select, selected }">
                              <v-chip
                                  v-bind="attrs"
                                  :input-value="selected+'#'"
                                  close
                                  label
                                  small
                                  @click="copyText(item)"
                                  @click:close="remove(item, hashtagsModel)"
                              >
                                {{ item.includes('#') ? item : ('#' + item) }}
                              </v-chip>
                            </template>
                          </v-combobox>


                          <v-combobox
                              v-model="mentionsModel"
                              :items="getCurrentCountry.twitterMentions"
                              chips
                              label="Mentions"
                              multiple
                              outlined
                              hide-selected
                          >
                            <template v-slot:selection="{ attrs, item, select, selected }">
                              <v-chip
                                  v-bind="attrs"
                                  label
                                  small
                                  :input-value="selected+'#'"
                                  close
                                  @click="copyText(item)"
                                  @click:close="remove(item, mentionsModel)"
                              >
                                {{ item.includes('@') ? item : ('@' + item) }}
                              </v-chip>
                            </template>
                          </v-combobox>

                          <v-textarea counter outlined auto-grow rows="1"
                                      label="Tweet Exampel text"
                                      v-model="tweetExModel"

                          ></v-textarea>

                          <!--                          <v-textarea-->
                          <!--                              outlined-->
                          <!--                              auto-grow-->
                          <!--                              counter-->
                          <!--                              rows="1"-->
                          <!--                              prepend-inner-icon="mdi-twitter"-->
                          <!--                              label="Twitter Material"-->
                          <!--                              :rules="twitterMaterialRule"-->
                          <!--                              v-model="twitterMaterialModel"-->
                          <!--                          ></v-textarea>-->
                        </v-tab-item>


                        <v-tab-item>
                          <v-text-field
                              outlined
                              prepend-inner-icon="mdi-phone"
                              label="Phone Number"
                              v-model="phoneModel"
                          ></v-text-field>
                          <v-text-field
                              outlined
                              prepend-inner-icon="mdi-phone"
                              label="Who to call"
                              v-model="whoToCallModel"
                          ></v-text-field>
                        </v-tab-item>


                        <!--                        <v-tab-item>-->
                        <!--                          <v-text-field-->
                        <!--                              outlined-->
                        <!--                              prepend-inner-icon="mdi-email"-->
                        <!--                              label="Email address"-->
                        <!--                              v-model="emailModel"-->
                        <!--                          ></v-text-field>-->
                        <!--                          <v-textarea-->
                        <!--                              outlined-->
                        <!--                              counter-->
                        <!--                              auto-grow-->
                        <!--                              rows="1"-->
                        <!--                              prepend-inner-icon="mdi-email"-->
                        <!--                              label="Email template"-->
                        <!--                              :rules="twitterMaterialRule"-->
                        <!--                              v-model="twitterMaterialModel"-->
                        <!--                          ></v-textarea>-->
                        <!--                        </v-tab-item>-->
                      </v-tabs-items>
                    </v-tabs>


                    <!-- TODO:    Tabs END -->

                    <v-col cols="12" class="mb-4" v-if="!existingDocument">
                      <v-btn block large depressed dark color="orange darken--4" @click="submit()">
                        Submit
                      </v-btn>
                    </v-col>
                  </v-flex>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="getIsAutenticated" @click="sendEmails()">
        <v-btn>Send mail</v-btn>
      </v-row>
    </v-container>
    <v-overlay opacity=".8" :value="overlay" z-index="99">
      <div class="align-center d-flex">
        <div class="mr-2" :class="{'title': $vuetify.breakpoint.smAndDown, 'display-1': $vuetify.breakpoint.mdAndUp}">
          Text Copied
        </div>
        <div>
          <v-icon large color="green">mdi-checkbox-marked-circle</v-icon>
        </div>

      </div>
    </v-overlay>
    <v-dialog
        v-model="areYouSureDialog"
        width="500"
        v-if="existingDocument"
    >
      <v-card class="pa-4">
        <v-container class="pa-4">
          <v-row class="text-center">
            <v-col cols="12">
              Are you sure you want to delete?
            </v-col>
            <v-col cols="12">
              <v-btn depressed @click="areYouSureDialog = false" class="mr-4">Cancel</v-btn>
              <v-btn depressed dark color="red" @click="deleteActionClicked()">Delete</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mentions1} from '@/mentions/1'

export default {
  data: () => ({
    titleModel: '',
    titleRule: [
      v => !!v || 'Title is required',
      v => v.length <= 33 || 'Max 33 characters'],
    eventModel: '',
    eventRule: [v => !!v || 'Event is required'],
    actionModel: '',
    actionRule: [
      v => !!v || 'Action is required'],
    yourRightsModel: '',
    twitterMaterialModel: '',
    twitterMaterialRule: [v => v.length <= 280 || 'Max 280 characters'],
    twitterLinkModel: '',
    tweetExModel: '',
    instagramMaterialModel: '',
    instagramLinkModel: '',
    phoneModel: '',
    whoToCallModel: '',
    emailModel: '',
    existingDocument: null,
    areYouSureDialog: false,
    valid: true,
    tab: null,
    hashtagsModel: [],
    hashtagsItems: ['#IranRevolution', '#MahsaAmini', '#IRGCterrorists'],
    mentionsModel: [],
    overlay: false,
    twitterLinksModel: [{
      model: ''
    }]
  }),
  mounted() {
    // loggg(mentions1)
    // const dool = 'https://twitter.com/mhdiata/status/1608570217923502081';
    // console.log(dool.split('/')[5])

    if (this.$route.name === 'CurrentActionsUpdate') {
      this.existingDocument = this.getDocumentById({id: this.$route.params.id, collectionName: 'currentActions'});
      if (this.existingDocument) {
        this.titleModel = this.existingDocument.title;
        this.eventModel = this.existingDocument.event;
        this.actionModel = this.existingDocument.action;
        this.yourRightsModel = this.existingDocument.yourRights;
        this.twitterMaterialModel = this.existingDocument.twitterMaterial;
        this.twitterLinksModel = this.existingDocument.twitterLinks;
        this.instagramMaterialModel = this.existingDocument.instagramMaterial;
        this.instagramLinkModel = this.existingDocument.instagramLink;
        this.phoneModel = this.existingDocument.phone;
        this.whoToCallModel = this.existingDocument.whoToCall;
        this.emailModel = this.existingDocument.email;
        this.tweetExModel = this.existingDocument.tweetEx;
        this.mentionsModel = this.existingDocument.mentions;
        this.hashtagsModel = this.existingDocument.hashtags;
      }
    }
  },
  // watch:{
  //   chips(newQuestion, oldQuestion){
  //     if(this.chips.length > 0){
  //
  //       const cos = this.chips.map(chip => {
  //         if(!chip.includes('#')){
  //           return '#'+chip
  //         }
  //       })
  //       this.chips = {...cos};
  //     }
  //   }
  // },
  methods: {
    ...mapActions([
      'addItem',
      'updateItem',
      'deleteItem',
      'archiveItem',
      'sendEmails'
    ]),
    addTwitterLink() {
      this.twitterLinksModel.push({model: ''});
    },
    removeTwitterLink(index) {
      this.twitterLinksModel.splice(index, 1);
    },
    remove(item, model) {
      model.splice(model.indexOf(item), 1)
    },
    async submit() {
      let tempArr = []
      this.mentionsModel.forEach(item => {
        if (item.includes('@')) {
          tempArr.push(item)
        } else {
          tempArr.push('@' + item)
        }
      })
      this.mentionsModel = tempArr;

      tempArr = []
      this.hashtagsModel.forEach(item => {
        if (item.includes('#')) {
          tempArr.push(item)
        } else {
          tempArr.push('#' + item)
        }
      })
      this.hashtagsModel = tempArr;

      // loggg(tempArr)
      this.valid = await this.$refs.form.validate()
      if (this.valid) {
        const models = {
          title: this.titleModel,
          event: this.eventModel,
          action: this.actionModel,
          yourRights: this.yourRightsModel,
          twitterMaterial: this.twitterMaterialModel,
          twitterLinks: this.twitterLinksModel,
          instagramMaterial: this.instagramMaterialModel,
          instagramLink: this.instagramLinkModel,
          phone: this.phoneModel,
          email: this.emailModel,
          whoToCall: this.whoToCallModel,
          tweetEx: this.tweetExModel,
          mentions: this.mentionsModel,
          hashtags: this.hashtagsModel,
          mentions1: [...mentions1]
        }
        if (this.existingDocument) {
          // const curren = mentions1.find(men => men.twitterId === this.existingDocument.currentMention.twitterId)
          // currentMention: mentions1[0],
          // loggg(curren)
          this.updateItem({
            models: {
              ...models,
              id: this.existingDocument.id,
              dateCreated: this.existingDocument.dateCreated
            },
            collectionName: 'currentActions'
          }).then(() => {
            this.$refs.form.reset()
            this.$router.push({name: 'CurrentActionsItem', params: {id: this.existingDocument.id}})
          }).catch(error => {
            console.log(error)
          })
        } else {
          this.addItem({
            models: {...models, currentMention: mentions1[0], currentTwitterLink: this.twitterLinksModel[0].model},
            collectionName: 'currentActions',
          }).then(() => {
            this.$router.push({name: 'CurrentActions'})
          }).catch(error => {
            console.log(error)
          })
        }

      }
    },
    deleteActionClicked() {
      this.deleteItem({collectionName: 'currentActions', id: this.existingDocument.id}).then(() => {
        this.$router.push({name: 'CurrentActions'})
      }).catch(error => {
        console.log(error)
      })
    },
    copyText(material) {
      this.overlay = true;
      navigator.clipboard.writeText(material);
      setTimeout(() => {
        this.overlay = false;
      }, 700)
    },
    archiveItemClicked() {
      this.archiveItem({item: this.existingDocument, collectionName: 'currentActions'}).then(() => {
        this.$router.push({name: 'CurrentActions'})
      })
    }
  },
  computed: {
    ...mapGetters([
      'getDocumentById',
      'getCurrentCountry',
      'getIsAutenticated'
    ])
  }
}
</script>

<style scoped>
.block-badges {
  display: block !important;
}

.input-lable-icons {
  margin-top: -4px;
  margin-left: 3px
}
</style>