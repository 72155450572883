import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import {db, functions, auth} from './plugins/firebaseInit'
import i18n from '@/plugins/i18n';
import moment from 'moment'
import Vuetify from "vuetify";

window.loggg = function(data){
  console.log(JSON.parse(JSON.stringify(data)));
}

window.isProduction = window.location.hostname !== 'localhost'

window.moment = moment;
window.db = db;
window.functions = functions;
window.auth = auth;

// Vue.use(Vuetify, {
//   theme: {
//     primary: '#2473ad',
//     secondary: '#5e86aa',
//     accent: '#344F7E',
//     grey: '#b2b2b2',
//     orange: '#d96720',
//     orange_light: '#ef9d6f',
//     green: '#339a17'
//   }
// })

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  db,
  render: h => h(App)
}).$mount('#app')
