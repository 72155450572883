<template>
  <v-card elevation="0">
    <v-card-text class="text--primary pa-lg-5">
      <div class="align-center d-flex mb-4">
        <div class="mr-1">
          <v-avatar>
            <img
                @click="openUserInTwitter"
                :src="tweet.userData.profile_image_url"
                alt="tobias-billstrom"
            >
          </v-avatar>
        </div>
        <div>
          <div style="margin-bottom: -3px; font-size: 1.1em;font-family: system-ui;" @click="openUserInTwitter">
            <strong>
              {{ tweet.userData.name }}
            </strong>
          </div>
          <div @click="openUserInTwitter">
            <span style="color: #6e6e6e">
              @{{ tweet.userData.username }} .
            </span>
            <a @click="openUserInTwitter" style="font-weight: bold; text-decoration: none">Follow</a>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="align-self-start">
          <v-btn large icon color="blue" @click="openTweet">
            <v-icon>mdi-twitter</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="mb-4" style="font-size: 1.2em; font-family: system-ui;line-height: 1.3;" dir="auto">
        {{ text }}
      </div>
      <div v-if="tweet.entities && tweet.entities.urls && tweet.entities.urls.length > 0 && tweet.entities.urls[0].unwound_url">
        <div >
          <div>
            <img  alt="" :src="tweet.entities.urls[0].images[0].url" style="width: 100%;border: 1px solid #e8e8e8; border-radius: 15px 15px 0 0 "/>
          </div>
          <div style="padding:15px;border: 1px solid #e8e8e8; border-radius: 0 0 15px 15px; margin: -8px 1px 1px 1px">
            <div style="color:rgb(83, 100, 113);margin-bottom: 3px" >theguardian.com</div>
            <div class="shah-three-dots-text">{{ tweet.entities.urls[0].title }}</div>
            <div class="grey--text text--darken-2">{{ tweet.entities.urls[0].description }}</div>
          </div>
        </div>

      </div>
      <div v-if="tweet.includes && tweet.includes.type === 'video'">
        <video
            style="background-color: black; border-radius: 8px;"
            :src="video.url"
            width="100%"
            height="300"
            controls
        >
          The browser does not support videos.
        </video>
      </div>
      <div v-if="tweet.includes && tweet.includes.type === 'photo'">
        <v-img :src="tweet.includes.url"></v-img>
      </div>
      <div class="my-2 grey--text text--darken-1">
        {{ time }} . {{ date }}
      </div>
      <div class="d-flex">
        <div>
          <strong>{{ tweet.public_metrics.retweet_count }}</strong> <span
            class="grey--text text--darken-2">Retweets</span>
        </div>
        <div class="ml-4">
          <strong>{{ tweet.public_metrics.quote_count }}</strong> <span
            class="grey--text text--darken-2">Quote Tweets</span>
        </div>
        <div class="ml-4">
          <strong>{{ tweet.public_metrics.like_count }}</strong> <span class="grey--text text--darken-2">Likes</span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: [
    'tweet'
  ],
  mounted() {
  },
  methods: {
    openUserInTwitter() {
      window.open('https://twitter.com/' + this.tweet.userData.username, '_blank');
    },
    openTweet() {
      window.open(this.tweet.entities.urls[0].url, '_blank');
    }
  },
  computed: {
    video() {
      return this.tweet.includes.variants.find(vari => vari.bit_rate === (Math.max(...this.tweet.includes.variants.map(o => o.bit_rate ? o.bit_rate : 0))))
    },
    time() {
      const date1 = new Date(this.tweet.created_at);
      date1.setSeconds(0, 0);
      return date1.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
    },
    date() {
      // const date1 = new Date();
      const date = new Date(this.tweet.created_at);  // 2009-11-10
      const month = date.toLocaleString('default', {month: 'short'});
      const year = date.getFullYear();
      const day = date.getDate();
      return `${month} ${day}, ${year}`
    },
    text() {
      return this.tweet.text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
    }
  }
}
</script>

<style scoped>
.shah-three-dots-text {
  font-size: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;;
  white-space: nowrap;
}
</style>