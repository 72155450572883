<template>
  <div>
    <v-container v-if="!loading && getTweetsWithMentiondId">
      <v-card v-for="(tweet, index) in getTweetsWithMentiondId" :key="index" class="mb-2">
        <v-card-text class="text--primary">
          <div>
            {{ tweet.text }}
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
<!--    <v-container class="fill-height text-center" v-else>-->
<!--      <v-row>-->
<!--        <v-col cols="12">-->
<!--          <loading-sogand />-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </v-container>-->
</template>

<script>
import  { mapGetters, mapActions } from "vuex";
import LoadingSogand from '@/components/global-components/loading-sogand'

export default {
  components: {
    LoadingSogand
  },

  data(){
    return {
      loading: true
    }
  },
  mounted() {
    // this.setTweetsWithSogandHashtag(this.$route.params.id).then(res => {
    //   // this.loading = false;
    // })
    // this.setTweetsWithMentiondId(this.$route.params.id).then(res => {
    //   this.loading = false;
    // })
  },
  methods:{
    ...mapActions([
        'setTweetsWithMentiondId',
        // 'setTweetsWithSogandHashtag'
    ])
  },
  computed:{
    ...mapGetters([
        'getTweetsWithMentiondId'
    ])
  }
}
</script>

<style scoped>

</style>