<template>
  <div>
    <v-toolbar elevation="0">
      <div class="subtitle-1 font-weight-medium">
        {{ $t('report') }}
      </div>
      <v-spacer></v-spacer>
<!--      <v-btn icon>-->
<!--        <v-icon>mdi-filter-variant</v-icon>-->
<!--      </v-btn>-->
    </v-toolbar>
    <v-container class="shah-max-app-with">
      <v-row class="">
        <v-col cols="12">
          <v-card
              elevation="0"
              v-if="false"
          >
            <div style="display: flex; justify-content: right">
            <span class="badge-container">
              <v-icon dark size="25">mdi-twitter</v-icon>
            </span>
            </div>

            <!--          <div style="display: flex; justify-content: right">-->
            <!--            <span class="badge-container" style="background-color: #d30808; margin-right: 40px; width: 20px; height: 20px;">-->
            <!--              <v-icon dark size="15">mdi-exclamation-thick</v-icon>-->
            <!--            </span>-->
            <!--          </div>-->

            <v-card-text>
              <v-row class="text--primary">
                <v-col cols="12">
                  <div>
                    <v-icon small class="mr-1">mdi-account</v-icon>
                    Ali Khamenei #1
                  </div>

                  <div class="mt-2">
                    <v-icon small class="mr-1">mdi-flag</v-icon>
                    Harrased or intimated with violence
                  </div>

                  <div class="mt-2">
                    <v-icon small class="mr-1">mdi-text-box</v-icon>
                    Spreading hate, denying suppression, mocking the deceased protesters and their relatives.
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card elevation="0" v-if="getCurrentReports.length === 0">
            <v-card-text class="text--primary">{{ $t('there-is-no-reports') }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>

export default {
  data: () => ({
    getCurrentReports: []
  }),
  methods: {},
  mounted() {
  }
}
</script>

<style>
.badge-container {
  display: flex;
  position: absolute;
  justify-content: center;
  z-index: 100;
  /*background-color: #DE1898*/
  background-color: #0a7ff5;
  width: 40px;
  height: 40px;
  margin: -1px -1px 0 0;
  border-radius: 0 0 20px 20px;
}
</style>


