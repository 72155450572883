<template>
  <div>
    <div v-if="action && !loading">
      <v-toolbar elevation="0">
        <v-btn icon @click="$router.push({ name: 'CurrentActions' })" class="mr-4">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="subtitle-1 font-weight-medium">
        {{ action.title }}
      </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="editAction()" v-if="getIsAutenticated">
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container style="padding: 0" class="shah-max-app-with">
        <v-card class="mb-4" elevation="0">
          <!--        <v-card-title class="caption pa-3 grey lighten-4 grey&#45;&#45;text text&#45;&#45;darken-3 font-weight-medium">-->
          <!--                    Must Win Battle-->
          <!--                    <v-btn icon @click="showNextTweet()"><v-icon>mdi-skip-next</v-icon></v-btn>-->
          <!--                    <v-spacer></v-spacer>-->
          <!--                    <div class="subtitle-2 black&#45;&#45;text first-letter-upper-case">{{ getPassedTime }}</div>-->
          <!--        </v-card-title>-->
          <v-card-text class="text--primary pa-0  grey--text text--darken-3">
<!--            <v-row no-gutters  class="mt-3 px-5 py-3 text-center primary white&#45;&#45;text font-weight-medium">-->
<!--              <v-col cols="12">-->
<!--                See other {{ numberOfReactions }} acts-->
<!--              </v-col>-->
<!--            </v-row>-->
            <v-row no-gutters class="mt-3 px-5 py-4" align="center">

              <v-col cols="8">
                <!--              <div class="blue&#45;&#45;text text&#45;&#45;darken-2 font-weight-medium">Your mention will be:</div>-->
                <div style="align-items: center; display: flex">
                  <div>{{ action.currentMention.name }}</div>
                  <div>
                    <img width="25px" height="15px" style="border: 1px solid #f3f3f3; margin-top: 2px" class="ml-1"
                         :src="`https://flags.fmcdn.net/data/flags/small/${action.currentMention.country}.png`" alt="">
                  </div>
                </div>
                <div>{{ action.currentMention.role }}</div>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-btn dark depressed color="deep-orange accent-3" v-if="action.twitterLinks"
                       @click="copyMaterialAndChangeRoute()">
                  <!--                <v-icon small class="mr-2">mdi-hand-peace</v-icon>-->
                  <!--                <span>{{ $t('take-action-on-twitter') }}</span>-->
                  <span>Act now</span>
                  <v-icon small class="ml-1">mdi-chevron-right</v-icon>
                </v-btn>
<!--                <v-btn dark text color="primary" class="mr-4 mt-1 none-text-transform" v-if="action.twitterLinks"-->
<!--                       @click="copyMaterialAndChangeRoute()">-->
<!--                  &lt;!&ndash;                <v-icon small class="mr-2">mdi-hand-peace</v-icon>&ndash;&gt;-->
<!--                  &lt;!&ndash;                <span>{{ $t('take-action-on-twitter') }}</span>&ndash;&gt;-->
<!--                  <span>Act now</span>-->
<!--&lt;!&ndash;                  <v-icon small class="ml-1">mdi-chevron-right</v-icon>&ndash;&gt;-->
<!--                </v-btn>-->
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="grey lighten-4 py-3">
              <v-col cols="2">
                <v-btn class="ml-3" icon @click="showPreviousTweet()" :disabled="0 >= this.tweetCurrentIndex">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="8" class="text-center">
                <div>
                  Choose your retweet post
                </div>
                <div>
                  <v-icon size="small" :color="loadColor(index)" class="mr-1" v-for="(dot, index) in tweets"
                          :key="index">mdi-circle
                  </v-icon>
                </div>
                <div></div>
              </v-col>
              <v-col cols="2" class="text-right">
                <v-btn class="mr-3" icon @click="showNextTweet()" :disabled="tweets.length <= tweetCurrentIndex+1">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <current-actions-tweet v-if="tweet" :tweet="tweet"/>
            <!--          <div class="pa-4" v-else>{{ action.event }}</div>-->
          </v-card-text>
        </v-card>

        <v-card class="mb-2" elevation="0" v-ripple @click="goToReactions()">
          <v-card-text class="font-weight-medium pa-3 grey lighten-4">
            <v-row no-gutters align="center">
              <v-col cols="12" class="primary--text text-center">
                See other {{ numberOfReactions }} acts <v-icon class="" color="primary">mdi-chevron-right</v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-2" elevation="0" v-if="action.yourRights">
          <v-card-title class="subtitle-1 pa-3 grey lighten-4 red--text text--darken-2 font-weight-medium">
            {{ $t('your-rights') }}
          </v-card-title>
          <v-card-text class="text--primary pt-4 pa-lg-5">
            {{ action.yourRights }}
          </v-card-text>
        </v-card>

        <!--      <v-card class="mb-2" elevation="0">-->
        <!--        <v-card-title class="subtitle-1 pa-3 grey lighten-4  red&#45;&#45;text text&#45;&#45;darken-2 font-weight-medium">-->
        <!--          {{ $t('what-can-i-do') }}-->
        <!--          <v-btn small icon color="primary" @click="actionModalInformation = true">-->
        <!--            <v-icon>mdi-information</v-icon>-->
        <!--          </v-btn>-->
        <!--        </v-card-title>-->
        <!--        <v-card-text class="text&#45;&#45;primary pt-4 pa-lg-5">-->
        <!--          &lt;!&ndash;          {{ action.action }}&ndash;&gt;-->
        <!--          &lt;!&ndash;          <v-divider class="my-4"></v-divider>&ndash;&gt;-->
        <!--          <v-row no-gutters class="pt-1">-->
        <!--            <v-col cols="12" class="mb-2">-->
        <!--              <v-btn block dark depressed color="blue-grey darken-4" v-if="action.twitterLinks"-->
        <!--                     @click="copyMaterialAndChangeRoute()" class="mr-3 mb-2">-->
        <!--                <v-icon small class="mr-2">mdi-twitter</v-icon>-->
        <!--                <span>{{ $t('take-action-on-twitter') }}</span>-->
        <!--              </v-btn>-->


        <!--              &lt;!&ndash;              <v-btn block dark depressed color="blue-grey darken-4" v-if="action.instagramLink"&ndash;&gt;-->
        <!--              &lt;!&ndash;                     @click="copyMaterialAndChangeRoute(action.instagramLink, action.instagramMaterial)"&ndash;&gt;-->
        <!--              &lt;!&ndash;                     class="mr-3 mb-2">&ndash;&gt;-->
        <!--              &lt;!&ndash;                <v-icon small class="mr-2">mdi-instagram</v-icon>&ndash;&gt;-->
        <!--              &lt;!&ndash;                <span>Kopiera material och öppna Instagram</span>&ndash;&gt;-->
        <!--              &lt;!&ndash;              </v-btn>&ndash;&gt;-->

        <!--              <v-btn block dark depressed color="blue-grey darken-4" v-if="action.email"-->
        <!--                     @click="copyMaterial(action.email, 'email')" class="mr-3 mb-2">-->
        <!--                <div v-if="emailCopied.status">-->
        <!--                  {{ emailCopied.message }}-->
        <!--                </div>-->
        <!--                <div v-else>-->
        <!--                  <v-icon small class="mr-2">mdi-email</v-icon>-->
        <!--                  <span>{{ action.email }}</span>-->
        <!--                </div>-->
        <!--              </v-btn>-->

        <!--              &lt;!&ndash;                     @click="copyMaterial(action.phone, 'phone')" &ndash;&gt;-->
        <!--              <v-btn block dark depressed color="blue-grey darken-4" v-if="action.phone"-->
        <!--                     class="mr-3 mb-2">-->
        <!--                &lt;!&ndash;                <div v-if="phoneCopied.status">&ndash;&gt;-->
        <!--                &lt;!&ndash;                  {{ phoneCopied.message }}&ndash;&gt;-->
        <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
        <!--                <div>-->

        <!--                  <a :href="'tel:' + getCurrentCountry.dialingCode + action.phone"-->
        <!--                     style="color:white; text-decoration: none">-->
        <!--                    <v-icon small class="mr-2">mdi-phone</v-icon>-->
        <!--                    <span>0{{ action.phone }} - {{ action.whoToCall }}</span>-->
        <!--                  </a>-->
        <!--                </div>-->
        <!--              </v-btn>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-card-text>-->
        <!--      </v-card>-->
        <!--      <v-card elevation="0" v-if="action.sogand">-->
        <!--        <v-card-title class="subtitle-1 pa-3 grey lighten-4  red&#45;&#45;text text&#45;&#45;darken-2 font-weight-medium">-->
        <!--          People who already reacted-->
        <!--        </v-card-title>-->
        <!--        <v-card-text class="text&#45;&#45;primary">-->
        <!--          <v-row>-->
        <!--            <v-col class="mt-4" cols="12">-->
        <!--              <v-btn @click="$router.push({ name: 'MentionsWithTwitterId', params: { id:  mentionedPersonWithoutAtSign} })">See tweets for mentioned person</v-btn>-->
        <!--&lt;!&ndash;              <v-btn @click="$router.push({ name: 'MentionsWithTwitterId', params: { id:  action.sogand} })">See tweets for mentioned person</v-btn>&ndash;&gt;-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-card-text>-->
        <!--      </v-card>-->
        <v-dialog
            v-model="actionModalInformation"
            width="500"
        >
          <v-card class="" outlined>
            <v-card-title class="subtitle-1 font-weight-bold grey lighten-4">
              <span>Inför Twitter handlingar</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="actionModalInformation = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="text--primary mt-4">
              Klicka på knappen <strong>Agera på Twitter</strong>. När Twitter öppnas kommer nedanstående uppgifter att
              finnas på plats: <br><br>

              1. De ansvariga som bör bli nämnda <br>
              2. Hashtaggar <br>
              3. Länk till Tweeten som delas <br>


            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </div>
    <div v-else>
      <v-progress-linear
          indeterminate
          color="primary"
      ></v-progress-linear>
      <v-toolbar elevation="0">
        <div class="subtitle-1 font-weight-medium">

        </div>
        <v-spacer></v-spacer>
        <!--      <v-btn icon>-->
        <!--        <v-icon>mdi-filter-variant</v-icon>-->
        <!--      </v-btn>-->
      </v-toolbar>
      <v-container fill-height class="text-center shah-max-app-with">
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader
                v-bind="attrs"
                type="table-heading, list-item-two-line, image, table-tfoot"
            ></v-skeleton-loader>
            <!--            <v-progress-circular-->
            <!--                :size="50"-->
            <!--                color="primary"-->
            <!--                indeterminate-->
            <!--            ></v-progress-circular>-->
          </v-col>
        </v-row>

      </v-container>
    </div>

  </div>

</template>

<script>
import {mapGetters, mapActions} from "vuex";
import CurrentActionsTweet from "@/components/current-actions/current-actions-tweet";
import {mentions1} from '@/mentions/1'

export default {
  components: {
    CurrentActionsTweet
  },
  data: () => ({
    actionModalInformation: false,
    action: null,
    passedTime: null,
    emailCopied: {message: 'Email copied', status: false},
    phoneCopied: {message: 'Phone number copied', status: false},
    tweet: null,
    mentionedPersonWithoutAtSign: null,
    loading: true,
    tweets: [],
    currentTweet: null,
    tweetCurrentIndex: 0,
    attrs: {
      class: 'mb-6',
      boilerplate: true,
      elevation: 0,
    },
  }),
  mounted() {
    this.action = this.getDocumentById({id: this.$route.params.id, collectionName: 'currentActions'});
    // loggg(this.action)
    // this.action.currentMention = mentions1[3]
    if (this.action && this.action.id) {
      this.setCurrentActionTweets(this.action).then(result => {
        this.tweets = result.data;
        // loggg(this.tweets)
        if (this.tweets.length > 0) {
          this.tweet = this.tweets[0];
          // loggg(this.tweet);
          this.loading = false;
        }
        // loggg('this.tweets')
        // loggg(this.tweets)
      })
    }


    // this.tweet = this.getTweetById(this.action.currentTwitterLink.split('/')[5]);
    // loggg(this.action)
    // console.log(this.getTweetById(this.action.currentTwitterLink.split('/')[5]))

    // const mentionedPerson = this.action.twitterMaterial.split('\n')[0];
    // this.mentionedPersonWithoutAtSign = this.action.mentions[0].substring(1);


    // let today = yoolo.toISOString().slice(0, 10)
  },
  computed: {
    ...mapGetters([
      'getDocumentById',
      'getIsAutenticated',
      'getTweetById',
      'getCurrentCountry',
      'getReactionsById'
    ]),
    getPassedTime() {
      if (this.action.dateCreated) {
        return moment.duration(moment().diff(this.action.dateCreated.toDate())).humanize() + " " + this.$t('ago');
      } else {
        return 'unknown';
      }
    },
    numberOfReactions() {
      // const react = this.getReactionsById(currentAction.currentTwitterLink.split('/')[5]);
      if (this.getReactionsById(this.action.id)) {
        const react = this.getReactionsById(this.action.id);
        // loggg('react')
        // loggg(react)
        return react.total_tweet_count;
      } else {
        return ''
      }

    },
  },
  methods: {
    ...mapActions([
      'checkForPissibleMentionShift',
      'setCurrentActionTweets'
    ]),
    copyMaterialAndChangeRoute() {
      // loggg(mentions1)
      const currentTweetLink = this.action.twitterLinks.find(link => this.tweets[this.tweetCurrentIndex].id === link.model.split('/')[5])
      let tweetMaterial = '';
      tweetMaterial += this.action.tweetEx;
      tweetMaterial += '\n';
      tweetMaterial += this.action.currentMention.twitterId;
      tweetMaterial += '\n \n';
      tweetMaterial += this.action.hashtags.join("\n");
      tweetMaterial += '\n';
      tweetMaterial += this.action.sogand
      tweetMaterial += '\n \n';
      tweetMaterial += currentTweetLink.model;

      this.checkForPissibleMentionShift({action: this.action});

      let yolo = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(tweetMaterial);
      if (window.screen.width > 550) {
        window.open(yolo, '_blank');
      } else {
        window.open(yolo, "_self");
      }

    },
    copyMaterial(material, type) {
      if (type === 'phone') {
        this.phoneCopied.status = true;
        navigator.clipboard.writeText(material);
        setTimeout(() => {
          this.phoneCopied.status = false;
        }, 1000)
      } else if (type === 'email') {
        this.emailCopied.status = true;
        navigator.clipboard.writeText(material);
        setTimeout(() => {
          this.emailCopied.status = false;
        }, 1000)
      }
    },
    editAction() {
      this.$router.push({name: 'CurrentActionsUpdate', params: {id: this.$route.params.id}})
    },
    showNextTweet() {
      if (this.tweets.length > this.tweetCurrentIndex + 1) {
        this.tweet = this.tweets[this.tweetCurrentIndex + 1]
        this.tweetCurrentIndex++;
      }
    },
    showPreviousTweet() {
      if (0 < this.tweetCurrentIndex) {
        this.tweet = this.tweets[this.tweetCurrentIndex - 1]
        this.tweetCurrentIndex--;
      }
    },
    loadColor(dotIndex) {
      // const indexOfCurrentTweet = this.tweets.indexOf(twee => twee.id === this.tweet.id);
      return dotIndex === this.tweetCurrentIndex ? 'grey darken-3' : 'grey lighten-2';
    },
    goToReactions(){
      let yolo = "https://twitter.com/hashtag/Sogand19?src=hashtag_click";
      if (window.screen.width > 550) {
        window.open(yolo, '_blank');
      } else {
        window.open(yolo, "_self");
      }
    }

  },
}
</script>

<style scoped>
.v-btn {

  font-size: 13px;
  font-weight: normal;
}

</style>