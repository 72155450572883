<template>
  <div>
    <v-toolbar elevation="0">
      <v-btn icon @click="$router.push({ name: 'Contacts' })" class="mr-4">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="subtitle-1 font-weight-medium">
        {{ existingDocument ? 'Update contact' : 'Add new contact' }}
      </span>
      <v-spacer v-if="existingDocument"></v-spacer>
      <v-btn icon color="indigo darken-2" v-if="existingDocument" class="mr-1" @click="submit()">
        <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn icon color="indigo darken-2" v-if="existingDocument" class="mr-1"
             @click="$router.push({ name: 'Contacts'})">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn icon color="indigo darken-2" v-if="existingDocument" class="mr-1" @click="console.log('archive')">
        <v-icon>mdi-archive-arrow-down-outline</v-icon>
      </v-btn>
      <v-btn icon color="red darken-2" v-if="existingDocument" @click="areYouSureDialog = true">
        <v-icon>mdi-delete</v-icon>
      </v-btn>

    </v-toolbar>

    <v-container class="shah-max-app-with">
      <v-row>
        <v-col cols="12">
          <v-card elevation="0">
            <v-card-text class="text--primary pa-lg-8">
              <v-form ref="form"
                      v-model="valid"
                      lazy-validation>
                <v-row no-gutters>
                  <v-col cols="12" class="mb-3">
                    <v-card outlined style="background-color: #f6f6f6;"
                            class="mb-2 d-flex align-center justify-center" @click="click1">
                      <div style="position: absolute">
                        <div v-if="!previewImageExists && !imgUrl" class="mt-1">
                          <v-icon class="mr-2" small style="margin-top: -2px">mdi-paperclip</v-icon>
                          <span class="subtitle-2">Choose photo</span>
                        </div>

                        <input type="file" ref="input1"
                               style="display: none"
                               @change="previewImage" accept="image/*">
                      </div>
                      <div>
                        <img id="output" height="150" style="padding-top: 10px;padding-bottom: 2px"/>
                        <img v-if="imgUrl && !previewImageExists" :src="imgUrl" height="150" style="padding-top: 10px;padding-bottom: 2px" />
                      </div>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Name" required outlined
                        :rules="nameRule"
                        v-model="nameModel"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Title" required outlined
                        v-model="titleModel"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Instagram" required outlined
                        v-model="instagramModel"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Twitter" required outlined
                        v-model="twitterModel"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Facebook" required outlined
                        v-model="facebookModel"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Phone" required outlined
                        v-model="phoneModel"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Email" required outlined
                        v-model="emailModel"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="mb-4" v-if="!existingDocument">
                    <v-btn block large depressed dark color="orange darken--4" @click="submit()">
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="areYouSureDialog"
        width="500"
        v-if="existingDocument"
    >
      <v-card class="pa-4">
        <v-container class="pa-4">
          <v-row class="text-center">
            <v-col cols="12">
              Are you sure you want to delete?
            </v-col>
            <v-col cols="12">
              <v-btn depressed @click="areYouSureDialog = false" class="mr-4">Cancel</v-btn>
              <v-btn depressed dark color="red" @click="deleteDocumentClicked()">Delete</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  data: () => ({
    valid: true,
    nameModel: '',
    nameRule: [
      v => !!v || 'Name is required',
    ],
    titleModel: '',
    titleRule: [
      v => !!v || 'Title is required',
    ],
    instagramModel: '',
    twitterModel: '',
    facebookModel: '',
    phoneModel: '',
    emailModel: '',
    existingDocument: null,
    areYouSureDialog: false,
    imgUrl: '',
    imageData: null,
    previewImageExists: false
  }),
  mounted() {
    if (this.$route.name === 'ContactsUpdate') {
      this.existingDocument = this.getDocumentById({id: this.$route.params.id, collectionName: 'contacts'});
      if(this.existingDocument){
        this.nameModel = this.existingDocument.name;
        this.titleModel = this.existingDocument.title;
        this.instagramModel = this.existingDocument.instagram;
        this.twitterModel = this.existingDocument.twitter;
        this.facebookModel = this.existingDocument.facebook;
        this.phoneModel = this.existingDocument.phone;
        this.emailModel = this.existingDocument.email;
        this.imgUrl = this.existingDocument.imgUrl;
      }
    }
  },
  methods: {
    ...mapActions([
      'addItem',
      'updateItem',
      'deleteItem',
      'uploadImage'
    ]),
    async submit() {
      // if(this.imageData && this.imageData.name){
      //   console.log(this.imageData)
      // } else {
      //   console.log(this.existingDocument.imgUrl)
      // }


      this.valid = await this.$refs.form.validate()
      if (this.valid) {

        const models = {
          name: this.nameModel,
          title: this.titleModel,
          instagram: this.instagramModel,
          twitter: this.twitterModel,
          facebook: this.facebookModel,
          phone: this.phoneModel,
          email: this.emailModel,
          imgUrl: this.imageData ? this.imageData : this.imgUrl ? this.imgUrl : null
        }

        if (this.existingDocument) {
          this.updateItem({
            models: {...models, id: this.existingDocument.id},
            collectionName: 'contacts'
          }).then(() => {
            this.$refs.form.reset()
            this.$router.push({name: 'Contacts'})
          }).catch(error => {
            console.log(error)
          })
        } else {
          this.addItem({models: models, collectionName: 'contacts'}).then(() => {
            this.$router.push({name: 'Contacts'})
          }).catch(error => {
            console.log(error)
          })
        }
      } else {
        console.log('form not valid')
      }
    },
    deleteDocumentClicked() {
      this.deleteItem({collectionName: 'contacts', id: this.existingDocument.id}).then(() => {
        this.$router.push({name: 'Contacts'})
      }).catch(error => {
        console.log(error)
      })
    },
    click1() {
      this.$refs.input1.click()
    },

    previewImage(event) {
      this.imgUrl = null;
      this.imageData = event.target.files[0];
      let output = document.getElementById('output');
      output.src = URL.createObjectURL(event.target.files[0]);
      output.onload = function () {
        URL.revokeObjectURL(output.src) // free memory
      }
      this.previewImageExists = true
    }
  },
  computed: {
    ...mapGetters([
      'getDocumentById'
    ])
  }
}
</script>

<style scoped>
</style>