import { io } from 'socket.io-client'
import store from '@/store/store'
class SocketioService {
  socket;
  constructor () {}

  setupSocketConnection (ids) {
    this.socket = io('http://localhost:3000')
    // this.socket = io('https://sogand-backend.onrender.com')

    // console.log(ids)
    this.socket.emit('load the tweet', ids)

    this.socket.on('tweet is loaded', (response) =>{
      // loggg(response)
      try {
        store.dispatch('answerFromServerSetTweet', response)
      } catch (error) {
        console.error(error);
      }
    })
  }
}

export default new SocketioService()
