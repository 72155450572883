import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth'
import {
  doc,
  setDoc,
  addDoc,
  collection,
  query,
  getDocs,
  getDoc,
  onSnapshot,
  updateDoc,
  serverTimestamp,
  deleteDoc
} from "firebase/firestore";
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import Vue from 'vue'
import Vuex from 'vuex'
import {httpsCallable, httpsCallableFromURL} from 'firebase/functions';
import SocketioService from '@/services/socketio.service'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: null,
    getIsAutenticated: false,
    countries: [],
    currentCountry: null,
    countryFlags: [],
    isAppLoading: true,
    currentActions: [],
    demonstrations: [],
    contacts: [],
    tweets: [],
    tweetsWithMentiondId: [],
    countOfCurrentActions: null,
    reactions: [],

  },
  mutations: {
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    SET_CURRENT_COUNTRY(state, currentCountry) {
      state.currentCountry = currentCountry;
      moment.locale(currentCountry.abbreviation)
    },
    SET_COUNTRY_FLAGS(state) {
      state.countries.forEach(country => {
        state.countryFlags.push({abbreviation: country.abbreviation, name: country.name})
      })
    },
    SET_COLLECTION(state, {collectionName, collection}) {
      state[collectionName] = []
      state[collectionName] = [...collection];
    },
    SET_CURRENT_USER(state, user) {
      state.currentUser = user;
    },
    LOGOUT(state) {
      state.currentUser = null;
    },
    CHANGE_STATUS_APP_LOADING(state, newStatus) {
      state.isAppLoading = newStatus;
    },
    ANSWER_FROM_SERVER_SET_TWEETS(state, tweets) {
      state.tweets = tweets;
    },
    ANSWER_FROM_SERVER_SET_REACTIONS(state, reactions) {
      state.reactions = reactions;
    },
    SET_TWEETS_WITH_MENTION_ID(state, result) {
      state.tweetsWithMentiondId = result;
    },
    SET_COUNT_OF_CURRENT_ACTIONS(state, counts) {
      state.countOfCurrentActions = counts;
    }
  },
  actions: {
    async init({commit, dispatch, state}, countryAbbreviation) {
      // fetch('https://api.ipregistry.co/?key=tryout')
      //   .then(function (response) {
      //     return response.json();
      //   })
      //   .then(function (payload) {
      //     // console.log(payload.location.country.name + ', ' + payload.location.city);
      //     console.log(payload.location.country);
      //   });


      // fetch('https://www.bbc.com/news/world-middle-east-64196635').then(function (response) {
      //   // The API call was successful!
      //   return response.json();
      // }).then(function (data) {
      //   // This is the JSON from our response
      //   console.log(data);
      // }).catch(function (err) {
      //   // There was an error
      //   console.warn('Something went wrong.', err);
      // });

      await dispatch('setCountriesAndFlags', countryAbbreviation);

      await dispatch('setCurrentUser');

      await dispatch('setCollection', 'currentActions');
      await dispatch('setCollection', 'demonstrations');
      await dispatch('setCollection', 'contacts');
      await dispatch('setCountOfCurrentAction');


      if (state.currentActions) await dispatch('setTweetsFromFireBaseFunctions')

      // await SocketioService.setupSocketConnection(commaSeperatedIds);
    },
    async setCountOfCurrentAction({commit}) {
      return new Promise((resolve, reject) => {

        const unsub = onSnapshot(doc(db, "countOfCurrentActions", "counter"), (doc) => {
          commit('SET_COUNT_OF_CURRENT_ACTIONS', doc.data().counter)
          resolve();
        });
      })
    },
    async checkForPissibleMentionShift({commit, state}, {action}) {
      // loggg('kire')
      // loggg(action)
      // const itemIndex = action.twitterLinks.findIndex(item => item.model === action.currentTwitterLink)
      // const newIndex = action.twitterLinks.length > (itemIndex + 1) ? (itemIndex + 1) : 0
      // const ref = doc(db, "countries", state.currentCountry.abbreviation, "currentActions", action.id)
      // await updateDoc(ref, {
      //   currentTwitterLink: action.twitterLinks[newIndex].model
      // })
      // loggg(currentMentionIndex)
      // loggg(action.mentions1)
      const currentMentionIndex = action.mentions1.findIndex(item => item.twitterId === action.currentMention.twitterId);
      const newMentionIndex = action.mentions1.length > (currentMentionIndex + 1) ? (currentMentionIndex + 1) : 0;
      const mentionRef = doc(db, "countries", state.currentCountry.abbreviation, "currentActions", action.id);
      await updateDoc(mentionRef, {
        currentMention: action.mentions1[newMentionIndex]
      });

      // const backendUrl = window.location.hostname === 'localhost' ?
      //   'http://127.0.0.1:5001/zan-zendegi-azadi-972e5/us-central1/getTweetsWithSogandHashtag' :
      //   'https://us-central1-zan-zendegi-azadi-972e5.cloudfunctions.net/getTweetsWithSogandHashtag'
      // const getTweetsWithSogandHashtag = httpsCallableFromURL(functions, backendUrl);
      // const result = await getTweetsWithSogandHashtag({
      //   action: action,
      //   countryAbbreviation: state.currentCountry.abbreviation
      // })
      // loggg('getTweetsWithSogandHashtag')
      // loggg(result)
      // commit('SET_TWEETS_WITH_MENTION_ID', result.data)
      // resolve(result);

    },
    // async setTweetsWithMentiondId({commit}, id) {
    //   return new Promise(async (resolve, reject) => {
    //     const backendUrl = window.location.hostname === 'localhost' ?
    //       'http://127.0.0.1:5001/sogand-backend/us-central1/getTweetsWithMentionedId' :
    //       'https://us-central1-sogand-backend.cloudfunctions.net/getTweetsWithMentionedId'
    //     const getTweetsWithMentionedId = httpsCallableFromURL(functions, backendUrl);
    //     const result = await getTweetsWithMentionedId({id: id})
    //     loggg(result)
    //     commit('SET_TWEETS_WITH_MENTION_ID', result.data)
    //     resolve(result);
    //   })
    //
    // },
    async sendEmails({commit, state}) {
      const backendUrl = window.location.hostname === 'localhost' ?
        'http://127.0.0.1:5001/zan-zendegi-azadi-972e5/us-central1/sendEmails' :
        'https://us-central1-zan-zendegi-azadi-972e5.cloudfunctions.net/sendEmails'
      const sendEmails = httpsCallableFromURL(functions, backendUrl);
      await sendEmails();
    },
    async setCurrentActionTweets({commit}, currentAction) {
      return new Promise(async (resolve, reject) => {
        const backendUrl = window.location.hostname === 'localhost' ?
          'http://127.0.0.1:5001/zan-zendegi-azadi-972e5/us-central1/getCurrentActionTweets' :
          'https://us-central1-zan-zendegi-azadi-972e5.cloudfunctions.net/getCurrentActionTweets'
        const tweetIds = []
        currentAction.twitterLinks.forEach(link => {
          tweetIds.push(link.model.split('/')[5])
        })
        const commaSeperatedIds = tweetIds.join(',')
        const getTweets = httpsCallableFromURL(functions, backendUrl);
        const result = await getTweets({ids: commaSeperatedIds})
        resolve(result)
        // loggg('result')
        // loggg(result)
      })
    },
    async setTweetsFromFireBaseFunctions({commit, state}) {
      const backendUrl = window.location.hostname === 'localhost' ?
        'http://127.0.0.1:5001/zan-zendegi-azadi-972e5/us-central1/getSogandCounters' :
        'https://us-central1-zan-zendegi-azadi-972e5.cloudfunctions.net/getSogandCounters'


      let sogandsWithActionIds = []
      state.currentActions.forEach(action => {
        // loggg(action)
        if (action.status === 'live') {
          sogandsWithActionIds.push({
            sogand: action.sogand,
            actionId: action.id
          })
        }
      })
      const getSogandCounters = httpsCallableFromURL(functions, backendUrl);
      const result = await getSogandCounters({sogandsWithActionIds})

      commit('ANSWER_FROM_SERVER_SET_REACTIONS', result.data);


      // loggg('result')
      // loggg(result)
      // const str = "select fielfff #IranRevolution #Sogand00001".split(" ");
      // const data=  str[str.findIndex(el=>el==="#IranRevolution\n")+1]
      // console.log(data)
      // let tweetIds = [];
      // let sogands = []
      // let kir = [];
      // state.currentActions.forEach(action => {
      //   if (action.twitterLinks) {
      //     const id = action.currentTwitterLink.split('/')[5];
      //     tweetIds.push(id)
      //     sogands.push({sogand: action.sogand, id: id})
      // const str = action.twitterMaterial.split("#");
      // console.log(str)
      // kir.push(str[str.findIndex(el=>el==="IranRevolution\n")+1])
      // }
      // });
      // loggg(sogands)
      // loggg(kir[0].substring(0, 10))

      // if (tweetIds.length > 0) {
      //   const commaSeperatedIds = tweetIds.join(',');
      //
      //   const backendUrl = window.location.hostname === 'localhost' ?
      //     'http://127.0.0.1:5001/zan-zendegi-azadi-972e5/us-central1/getTweets' :
      //     'https://us-central1-zan-zendegi-azadi-972e5.cloudfunctions.net/getTweets'


      // loggg(sogands)
      // const getTweets = httpsCallableFromURL(functions, backendUrl);
      // const result = await getTweets({ids: commaSeperatedIds, sogands: sogands})

      // loggg('result')
      // loggg(result.data)
      // commit('ANSWER_FROM_SERVER_SET_TWEETS', result.data.tweets);
      // commit('ANSWER_FROM_SERVER_SET_REACTIONS', result.data.reactions);
      // }
      commit('CHANGE_STATUS_APP_LOADING', false);
    },
    setCurrentUser({commit}) {
      return new Promise((resolve, reject) => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
          if (user) commit('SET_CURRENT_USER', user)
          resolve();
        });
      })
    },

    async setCountriesAndFlags({commit, state}, countryAbbreviation) {
      return new Promise(async (resolve, reject) => {
        const ref = collection(db, "countries");
        const snap = await getDocs(ref);
        const countries = snap.docs.map(doc => doc.data());
        commit('SET_COUNTRIES', countries);

        const currentCountry = countries.find(country => country.abbreviation === countryAbbreviation);
        if (currentCountry) {
          const defaultCountry = countries.find(country => country.abbreviation === 'eng');   // refresh nakonam chon miad to bad refresh mishe safe
          commit('SET_CURRENT_COUNTRY', currentCountry ? currentCountry : defaultCountry);
          commit('SET_COUNTRY_FLAGS');
          resolve();
        } else {
          location.replace(isProduction ? 'https://sogand.org' : 'http://localhost:8080')
        }
      })
    },
    // setCurrentCountry({commit, dispatch, state}, countryAbbreviation) {
    //   return new Promise(async (resolve, reject) => {
    //     commit('SET_CURRENT_COUNTRY', countryAbbreviation);
    //
    //     const kir = dispatch('setCollection', 'currentActions');
    //     const kir2 = dispatch('setCollection', 'demonstrations');
    //     const kir3 = dispatch('setCollection', 'contacts');
    //
    //     Promise.all([kir, kir2, kir3]).finally(() => {
    //       commit('CHANGE_STATUS_APP_LOADING', false);
    //       resolve();
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    async setCollection({commit, state}, collectionName) {
      return new Promise((resolve, reject) => {
        const query = collection(db, "countries", state.currentCountry.abbreviation, collectionName);
        const unsubscribe = onSnapshot(query, (querySnapshot) => {
          const collection = [];
          querySnapshot.forEach((doc) => {
            collection.push(doc.data());
          });
          commit('SET_COLLECTION', {collectionName: collectionName, collection: collection});
          resolve();
        })
      })

    },
    // async setCollection({commit, state}, collectionName) {
    //   const query = collection(db, "countries", state.currentCountry.abbreviation, collectionName);
    //   const unsubscribe = onSnapshot(query, (querySnapshot) => {
    //     const collection = [];
    //     querySnapshot.forEach((doc) => {
    //       collection.push(doc.data());
    //     });
    //     commit('SET_COLLECTION', {collection: collection, collectionName: collectionName});
    //   });
    // },
    uploadImage({commit}, imageData) {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const storageRef = ref(storage, 'images/' + imageData.name);
        uploadBytes(storageRef, imageData).then(() => {
          getDownloadURL(ref(storage, storageRef)).then(url => {
            resolve(url)
          }).catch(error => {
            reject(error)
          })
        }).catch(error => {
          reject(error)
        })
      })
    },
    async updateItem({commit, state, dispatch}, {models, collectionName}) {
      return new Promise(async (resolve, reject) => {
        let newImageUrl = null;
        if (models.imgUrl && models.imgUrl.name) {
          newImageUrl = await dispatch('uploadImage', models.imgUrl);
        }

        let modelsDateImg = null;
        if (collectionName === 'contacts' || collectionName === 'demonstrations') {
          modelsDateImg = {
            ...models,
            imgUrl: newImageUrl ? newImageUrl : models.imgUrl ? models.imgUrl : '',
            dateCreated: models.dateCreated ? models.dateCreated : serverTimestamp()
          }
        }

        const modelsDate = {
          ...models,
          dateCreated: models.dateCreated ? models.dateCreated : serverTimestamp()
        }

        const ref = doc(db, "countries", state.currentCountry.abbreviation, collectionName, models.id)
        updateDoc(ref, collectionName === 'contacts' || collectionName === 'demonstrations' ? modelsDateImg : modelsDate)
          .then(() => {
            resolve();
          }).catch(error => {
          reject(error);
        })
      })
    },
    async addToCountOfCurrentActions({commit, state}) {
      const ref = doc(db, "countOfCurrentActions", "counter");
      await updateDoc(ref, {counter: state.countOfCurrentActions + 1})
    },
    async addSubscription({commit}, email) {
      return new Promise(async (resolve, reject) => {
        const snap = await getDocs(collection(db, "subscribers"));
        const subscribers = snap.docs.map(doc => doc.data());

        const emailExists = subscribers.some(subscriber => subscriber.email === email)
        if(emailExists){
          resolve({ status: 'error', text: 'You are already subscribed to the list.' });
        } else {
          addDoc(collection(db, 'subscribers'), {email: email})
            .then(() => {
              resolve({ status: 'success', text: 'You are now subscribed to the list.' });
            })
            .catch(err => console.log(err))
        }
      })
    },
    async addItem({commit, state, dispatch}, {models, collectionName}) {
      return new Promise(async (resolve, reject) => {
        if (collectionName === 'contacts' || collectionName === 'demonstrations') {
          models.imgUrl = await dispatch('uploadImage', models.imgUrl);
        }
        if (collectionName === 'currentActions') {
          await dispatch('addToCountOfCurrentActions');
        }

        addDoc(collection(db, "countries", state.currentCountry.abbreviation, collectionName), {
          ...models,
          countryAbbreviation: state.currentCountry.abbreviation,
          author: state.currentUser.displayName,
          status: "live",
          dateCreated: serverTimestamp(),
          sogand: '#Sogand' + state.countOfCurrentActions,
        }).then(response => {
          const ref = doc(db, "countries", state.currentCountry.abbreviation, collectionName, response.id)
          updateDoc(ref, {id: response.id}).then(() => {
            resolve();
          }).catch(error => {
            reject(error);
          })
        }).catch(error => {
          reject(error);
        });
      })
    },
    archiveItem({commit, state}, {item, collectionName}) {
      return new Promise((resolve, reject) => {
        const ref = doc(db, "countries", state.currentCountry.abbreviation, collectionName, item.id)
        updateDoc(ref, {
          status: "archive"
        }).then(() => {
          resolve();
        }).catch(error => {
          console.log(error)
        })
      })

    },
    deleteItem({commit, state}, {collectionName, id}) {
      return new Promise((resolve, reject) => {
        const ref = doc(db, "countries", state.currentCountry.abbreviation, collectionName, id)
        deleteDoc(ref)
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateReactionCounter({commit, state}, currentAction) {
      const ref = doc(db, "countries", state.currentCountry.abbreviation, "currentActions", currentAction.id)
      updateDoc(ref, {
        reactions: (currentAction.reactions || 0) + 1
      }).catch(error => {
        console.log(error)
      })
    },
    register({commit}, {email, password, displayName}) {
      return new Promise((resolve, reject) => {
        const auth = getAuth()
        createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            updateProfile(userCredential.user, {
              displayName: displayName
            }).then(() => {
              resolve();
            }).catch(error => {
              reject(error);
            })
          })
          .catch(error => {
            reject(error);
          })
      })

    },
    login({commit}, {email, password}) {
      return new Promise((resolve, reject) => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            commit('SET_CURRENT_USER', userCredential.user)
            resolve();
          })
          .catch(error => {
            reject(error);
          })
      })
    },
    logout({commit}) {
      const auth = getAuth()
      signOut(auth).then(() => {
        commit('LOGOUT')
      }).catch((error) => {
        console.log('error: ', error)
      })
    },
  },
  getters: {
    getIsAutenticated(state) {
      return state.currentUser;
    },
    getCountryFlags(state) {
      return state.countryFlags;
    },
    getCurrentCountry(state) {
      return state.currentCountry;
    },
    getIsAppLoading(state) {
      return state.isAppLoading;
    },
    getCurrentActions(state) {
      return state.currentActions;
    },
    getLocale(state) {
      if (state.currentCountry && state.currentCountry.abbreviation) {
        return state.currentCountry.abbreviation;
      } else {
        return 'en';
      }
    },
    getTweetById: (state) => (id) => {
      return state.tweets.find(tweet => tweet.id === id);
    },
    getTweets(state) {
      return state.tweets;
    },
    getDemonstrations(state) {
      return state.demonstrations;
    },
    getContacts(state) {
      return state.contacts;
    },
    getDocumentById: (state) => ({id, collectionName}) => {
      return state[collectionName].find(contact => contact.id === id)
    },
    getTweetsWithMentiondId(state) {
      return state.tweetsWithMentiondId;
    },
    getCountOfCurrentActions(state) {
      return state.countOfCurrentActions;
    },
    getReactionsById: (state) => (id) => {
      return state.reactions.find(reaction => reaction.id === id)
    }

  },

})
