<template>
  <div v-if="demonstration">
    <v-toolbar elevation="0">
      <v-btn icon @click="$router.push({ name: 'Demonstrations' })" class="mr-4">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="subtitle-1 font-weight-medium">
        {{ demonstration.title }}
      </span>
      <v-spacer></v-spacer>
      <v-btn icon @click="editDemonstration()" v-if="getIsAutenticated">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container class="shah-max-app-with">
      <v-card class="mb-2" elevation="0">
        <div class="grey darken-4" style="padding: 10px">
          <v-img
              v-if="demonstration.imgUrl"
              :src="demonstration.imgUrl"
              contain
              :height="isMobile ? '350px' : '350px'"
              @click="imageModal = !imageModal"
          ></v-img>
          <v-dialog
              v-model="imageModal"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"

          >
            <v-img
                style="background-color: rgba(0,0,0,0.9)"
                @click="imageModal = !imageModal"
                :src="demonstration.imgUrl"
                contain
                height="100%"
            >
            </v-img>
          </v-dialog>
        </div>

        <v-card-text class="text--primary pt-4 pa-lg-5">
          <div class="indigo--text text--darken-2 mt-4">
            <v-icon color="indigo darken--2" class="mr-2">mdi-map-marker-outline</v-icon>
            <span class="">
              {{ demonstration.place }}
            </span>
          </div>
          <div class="mb-2 mt-1 indigo--text text--darken-2 bold">
            <v-icon style="font-size: 20px; margin-left: 2px; margin-right: 10px" color="indigo darken--2">
              mdi-calendar-clock-outline
            </v-icon>
            <span class="">
              {{ demonstration.date }}
            <span class="mr-2 ml-2 grey--text text--lighten-1"> | </span>
              {{ demonstration.time }}
            </span>
          </div>
          <div class="mt-4">
            {{ demonstration.description }}
          </div>
          <v-row class="text-right mt-4">
            <v-col cols="12">
              <v-btn small depressed dark color="blue-grey darken-4" @click="openSourceClicked()">
                {{ $t('source') }} <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>

</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  data: () => ({
    demonstration: null,
    imageModal: false
  }),
  mounted() {
    this.demonstration = this.getDocumentById({id: this.$route.params.id, collectionName: 'demonstrations'});
  },
  computed: {
    ...mapGetters([
      'getDocumentById',
      'getIsAutenticated'
    ]),
    isMobile(){
      return window.innerWidth < 600
    }
  },
  methods: {
    ...mapActions([]),
    editDemonstration() {
      this.$router.push({name: 'DemonstrationsUpdate', params: {id: this.$route.params.id}})
    },
    openSourceClicked(){
      window.open(this.demonstration.manifestSource, '_blank');
    }
  },
}
</script>

<style scoped>


</style>