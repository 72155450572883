<template>
  <div>
    <v-toolbar elevation="0">
      <div class="subtitle-1 font-weight-medium">
        {{ $t('demonstrations') }}
      </div>
      <v-spacer></v-spacer>
      <!--      <v-btn icon>-->
      <!--        <v-icon>mdi-filter-variant</v-icon>-->
      <!--      </v-btn>-->
    </v-toolbar>
    <v-container class="shah-max-app-with">
      <v-card
          elevation="0"
          class="mb-3 pa-lg-3"
          v-for="(demonstration, index) in getDemonstrations"
          @click="openDemonstration(demonstration)"
      >
        <v-card-text class="text--primary">
          <v-row class="align-center">
            <v-col cols="10">
              <div class="deep-orange--text text--darken-3 shah-three-dots-text mb-3">
                {{ demonstration.title }}
              </div>
              <div class="indigo--text text--darken-2">
                 <v-icon color="indigo darken--2" class="mr-2">mdi-map-marker-outline</v-icon>
                <span class="">
                  {{ demonstration.place }}
                </span>
              </div>
              <div class="mb-2 mt-1 indigo--text text--darken-2 bold">
                <v-icon style="font-size: 20px; margin-left: 2px; margin-right: 10px" color="indigo darken--2">mdi-calendar-clock-outline</v-icon>
                <span class="">
                  {{ demonstration.date }}
                  <span class="mr-2 ml-2 grey--text text--lighten-1"> | </span>
                  {{ demonstration.time }}
                </span>
              </div>
              <div>
                {{ demonstration.description.substring(0, 80) + '...' }}
              </div>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-icon>mdi-chevron-right</v-icon>
            </v-col>
          </v-row>
<!--          <v-divider class="mt-4"></v-divider>-->
<!--          <v-row no-gutters class="mt-4 justify-space-between align-center">-->
<!--            <v-col class="">-->
<!--&lt;!&ndash;              <v-btn depressed dark color="indigo darken-2" class="none-text-transform">&ndash;&gt;-->
<!--&lt;!&ndash;                Se manifestskällan&ndash;&gt;-->
<!--&lt;!&ndash;              </v-btn>&ndash;&gt;-->
<!--            </v-col>-->
<!--            <v-col class="deep-orange&#45;&#45;text text&#45;&#45;darken-3 text-right">-->
<!--              Om 3 dagar-->
<!--            </v-col>-->
<!--          </v-row>-->
        </v-card-text>
      </v-card>
      <v-card elevation="0" v-if="getDemonstrations.length === 0">
        <v-card-text class="text--primary">{{ $t('there-is-no-demonstrations') }}</v-card-text>
      </v-card>
    </v-container>
  </div>

</template>

<script>

import {mapGetters} from "vuex";

export default {
  data: () => ({
    getCurrentDemonstrations: [
      {
        id: 0,
        title: 'Demonstrationen i Stockholm',
        place: 'Stockholm, Norra Bantorget',
        date: '29 December 2022',
        time: '11:00',
        description: 'Vi samlas för att låta politikerna veta att det här den sista varningen.',
        manifestSource: 'https://google.se'
      }
    ]
  }),
  mounted(){},
  methods: {
    openDemonstration(demonstration){
      this.$router.push('/' + this.getCurrentCountry.abbreviation + '/demonstrations/' + demonstration.id);
    }
  },
  computed: {
    ...mapGetters([
      'getDemonstrations',
      'getCurrentCountry'
    ]),
    showActions(){
      return this.getCurrentActions.length > 1;
    }
  },
}
</script>

<style scoped>
.shah-three-dots-text {
  font-size: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;;
  white-space: nowrap;
}
</style>


