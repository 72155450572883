<template>
  <v-speed-dial
      fab
      bottom
      right
      direction="top"
      fixed
      transition="slide-y-reverse-transition"
      v-if="getIsAutenticated"
  >
    <template v-slot:activator>
      <v-btn
          v-model="fab"
          color="deep-orange darken-3"
          dark
          fab
      >
        <v-icon v-if="fab">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>
<!--    <v-btn-->
<!--        fab-->
<!--        dark-->
<!--        small-->
<!--        color="indigo darken-2"-->
<!--        @click="$router.push({ name: 'ContactsAdd' })"-->
<!--    >-->
<!--      <v-icon>mdi-text-box</v-icon>-->
<!--    </v-btn>-->
    <v-btn
        fab
        dark
        small
        color="indigo darken-2"
        @click="$router.push({ name: 'DemonstrationsAdd' })"
    >
      <v-icon>mdi-account-group</v-icon>
    </v-btn>
    <v-btn
        fab
        dark
        small
        color="indigo darken-2"
        @click="$router.push({ name: 'CurrentActionsAdd' })"
    >
      <v-icon>mdi-hand-peace</v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data: () => ({
    fab: false,
  }),
  computed: {
    ...mapGetters([
      'getIsAppLoading',
      'getIsAutenticated'
    ])
  }
}
</script>

<style scoped>

</style>