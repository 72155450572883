// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions } from 'firebase/functions';
// import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8PCCdkfOzCc3_Xm7P1_FUGu2pQJf62Rk",
  authDomain: "zan-zendegi-azadi-972e5.firebaseapp.com",
  projectId: "zan-zendegi-azadi-972e5",
  storageBucket: "zan-zendegi-azadi-972e5.appspot.com",
  messagingSenderId: "656981353431",
  appId: "1:656981353431:web:1fd4abc4519251f651a192",
  measurementId: "G-2YZFB147DZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);
// const analytics = getAnalytics(app);
// logEvent(analytics, 'notification_received');
// console.log(analytics)
export {db, functions, auth}