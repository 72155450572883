<template>
  <div>
    <v-app-bar app color="blue-grey darken-4" dark elevation="0">
      <div class="d-flex align-center">
        <v-img
            @click="goHome()"
            contain
            transition="scale-transition"
            alt="Zan Zendegi Azadi"
            class="shrink mr-2"
            src="@/assets/sogand-icon-2-white.png"
            width="60"
            style="cursor: pointer"
        />
      </div>
      <video controls autoplay id="sogand-video" style="width: 0;">
        <source src="../../../src/assets/video/sogand.mp4" type="video/mp4" >
        Your browser does not support the video tag.
      </video>
      <v-spacer></v-spacer>
      <v-btn style="margin-top:1px" icon large class="mr-1 none-text-transform" @click="openFullscreen()">
        <v-icon>mdi-youtube</v-icon>
      </v-btn>
      <v-btn dark icon @click="subscribeDialog = true">
        <v-icon>mdi-bell-ring-outline</v-icon>
      </v-btn>
<!--      <video controls autoplay id="sogand-video">-->
<!--        <source src="../../../src/assets/video/sogand.mp4" type="video/mp4">-->
<!--        Your browser does not support the video tag.-->
<!--      </video>-->
<!--      <v-menu offset-y>-->
<!--        <template v-slot:activator="{ attrs, on }">-->
<!--          <v-btn-->
<!--              color="primary"-->
<!--              dark-->
<!--              v-bind="attrs"-->
<!--              v-on="on"-->
<!--          >-->
<!--            {{ languages.find(lan => lan.id === $i18n.locale).title }}-->
<!--          </v-btn>-->
<!--        </template>-->

<!--        <v-list>-->
<!--          <v-list-item-->
<!--              @click="$i18n.locale = item.id"-->
<!--              v-for="(item, index) in languages"-->
<!--              :key="index"-->
<!--              link-->
<!--          >-->
<!--            <v-list-item-title v-text="item.title"></v-list-item-title>-->
<!--          </v-list-item>-->
<!--        </v-list>-->
<!--      </v-menu>-->
      <v-dialog
          v-model="firstTimeVisitModal"
          width="500" persistent
      >
        <v-btn @click="openFullscreen()" fab small absolute style="margin-left:-17px; margin-top: -25px" depressed><v-icon>mdi-close</v-icon></v-btn>
        <video controls autoplay id="sogand-video">
          <source src="../../../src/assets/video/sogand.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </v-dialog>
      <v-dialog
          v-model="subscribeDialog"
          width="500"
      >
        <v-card>
          <v-progress-linear
              v-if="subscribeLoading"
              indeterminate
              color="primary"
          ></v-progress-linear>
          <div style="background-color: #f1f1f1; padding: 25px;display: flex;justify-content: space-between; align-items: center">
            <div class="font-weight-medium subtitle-2 text-center">
              <v-icon style="margin-top:-3px" class="mr-2">mdi-bell-ring-outline</v-icon>
              Subscribe to receive new Actions
            </div>
            <div><v-btn icon small text @click="subscribeDialog = false" depressed><v-icon>mdi-close</v-icon></v-btn></div>
          </div>
          <v-card-text class="text--primar pt-6">
            <v-container>
              <v-form
                  ref="form"
                  v-model="subscribeForm"
                  v-if="!youAreNowSubscribed"
              >
                <v-row no-gutters class="text--primary d-flex align-content-center justify-content-center">
                  <v-col cols="12">
                    <v-text-field :rules="emailRules" outlined label="Email" v-model="emailModel"></v-text-field>
                  </v-col>
                  <v-col cols="12" @click="subscribeClicked()">
                    <v-btn block color="primary" depressed>Subscribe</v-btn>
                  </v-col>
                  <v-col class="mt-3 red--text text--darken-3" v-if="alreadyExists">
                    Your are already subscribed to the list.
                  </v-col>
                </v-row>
              </v-form>
              <v-row v-else class="text--primary subtitle-1">
                <v-col cols="12" class="text-center">
                  <v-icon style="margin-top:-3px" class="mr-2" color="green darken-2">mdi-check-circle</v-icon> You are now subscried !
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-btn @click="subscribeDialog = false" depressed>Close</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--      <v-btn-->
      <!--          @click="$router.push({ name: 'Register' })"-->
      <!--          icon small dark-->
      <!--      >-->
      <!--        <v-icon>mdi-account-plus</v-icon>-->
      <!--      </v-btn>-->
      <!--      <v-btn-->
      <!--          @click="$router.push({ name: 'Login' })"-->
      <!--          icon small dark-->
      <!--      >-->
      <!--        <v-icon>mdi-login-variant</v-icon>-->
      <!--      </v-btn>-->
      <!--      <v-btn-->
      <!--          @click="dialog = true"-->
      <!--          target="_blank"-->
      <!--          text-->
      <!--      >-->
      <!--        <v-img style="margin: auto;"-->
      <!--               :src="getImgUrl(getCurrentCountry.name)"-->
      <!--               :alt="getCurrentCountry.name"-->
      <!--               width="30"-->
      <!--               >-->
      <!--        </v-img>-->
      <!--      </v-btn>-->
      <v-dialog
          v-model="dialog"
          width="500"
      >
        <v-card>
          <v-card-title>Choose your country</v-card-title>
          <v-card-text class="text--primary">
            <v-container>
              <v-row class="d-flex align-content-center justify-content-center">
                <v-col class="text-center" cols="4" v-for="(country, index) in getCountryFlags" :key="index"
                       @click="setCurrentCountryClicked(country.abbreviation)">
                  <img style="margin: auto; cursor: pointer" :src="getImgUrl(country.name)" :alt="country.name"
                       width="50"
                       v-ripple></img>
                  <div>
                    <span style="cursor: pointer">{{ country.name }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialog = false"
            >
              Close panel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
  </div>

</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";

export default {
  data() {
    return {
      alreadyExists: false,
      youAreNowSubscribed: false,
      subscribeLoading: false,
      dialog: false,
      subscribeDialog: false,
      subscribeForm: true,
      emailModel: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      languages: [
        { title: 'English', id: 'en' },
        { title: 'Persian', id: "fa" },
      ],
      firstTimeVisitModal: false
    }
  },
  mounted(){
    const elem = document.getElementById("sogand-video");
    elem.addEventListener(
        'fullscreenchange',
        function(event) {
          if (!document.fullscreenElement) {
            elem.pause();
            elem.style.display = 'none';
          }
        },
        false
    );
  },
  watch:{
    subscribeDialog:function(newValue, old){
      if(!newValue){
        this.emailModel = '';
        this.alreadyExists = false;
        this.youAreNowSubscribed = false;
      }
    }
  },
  methods: {
    ...mapActions([
      'setCurrentCountry',
      'addSubscription'
    ]),
    ...mapMutations([]),
    getImgUrl(flag) {
      let images = require.context('@/assets/flags/', false, /\.png$/);
      return images('./' + flag + ".png");
    },

    setCurrentCountryClicked(abbreviation) {

      window.location.hostname === 'localhost' ?
          location.replace("http://localhost:8080/" + abbreviation) :
          location.replace("https://sogand.org/" + abbreviation);
      // this.$router.push('/' + abbreviation).then(() => {
      //   location.reload();
      // })
      //     .catch(()=>{})
      //
      //
      // this.setCurrentCountry(abbreviation).then(() => {
      //   this.dialog = false;
      //   this.$i18n.locale = abbreviation;
      //   this.$router.push('/' + abbreviation).catch(()=>{})
      // }).catch(error => {
      //   console.log(error)
      // // })
    },
    goHome() {
      this.$router.push({name: 'CurrentActions'}).catch(() => {
      })
    },
    subscribeClicked() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        this.subscribeLoading = true;
        this.addSubscription(this.emailModel).then(res => {
          if(res.status === 'success'){
            this.subscribeLoading = false;
            this.youAreNowSubscribed = true;
          } else {
            this.subscribeLoading = false;
            this.alreadyExists = true;
          }

        })
      }
    },
    closeFirstTimeDialog(){
      this.firstTimeVisitModal = false;
      const videoPlayer = document.getElementById('sogand-video');
      videoPlayer.pause();
    },
    openFirstTimeDialog(){
      const video = document.getElementById('sogand-video')
      video.requestFullscreen()
    },
    openFullscreen(){
      const elem = document.getElementById("sogand-video");
      elem.style.display = 'flex';
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
      elem.play();
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentCountry',
      'getCountryFlags'
    ])
  }
}
</script>

<style scoped>

</style>