<template>
  <div style="margin-left: -30px">
    <img src="@/assets/head.png" id="head" alt="head">
    <transition-group>
      <img class="hand" src="@/assets/hand.png" id="hand-1" alt="hand" key="1">
      <img class="hand" src="@/assets/hand.png" id="hand-2" alt="hand-2" key="2">
      <img class="hand" src="@/assets/hand.png" id="hand-3" alt="hand-3" key="3">
    </transition-group>
    <div>
      <v-progress-linear
          color="primary"
          indeterminate
          rounded
          style="width: 100px; margin-left: auto;margin-right: auto;"
          height="6"
      ></v-progress-linear>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.hand{
  position:absolute;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  /*animation-iteration-count: infinite;*/
  animation-direction: alternate;
}
#head{
  width: 250px;
  position: relative;
  z-index: 1;
}
@keyframes hand-1 {
  0% {margin-left: -200px;margin-top:63px}
  50% {margin-left: -200px;margin-top:-30px}
  100% {margin-left: -200px;margin-top:-30px}
}
#hand-1{
  animation-name: hand-1;
  width: 40px;
  margin-left: -200px;margin-top:-30px
}

@keyframes hand-2 {
  0% {margin-left: -240px;margin-top:100px}
  50% {margin-left: -240px;margin-top:-10px}
  100% {margin-left: -240px;margin-top:-10px}
}
#hand-2{
  animation-name: hand-2;
  width: 20px;
  margin-left: -240px;margin-top:-10px
}

@keyframes hand-3 {
  0% {margin-left: -130px;margin-top:100px}
  50% {margin-left: -130px;margin-top:-25px}
  100% {margin-left: -130px;margin-top:-25px}
}
#hand-3{
  animation-name: hand-3;
  width: 25px;
  margin-left: -130px;margin-top:-25px
}
</style>