<template>
  <v-app>
    <app-top-bar v-if="!getIsAppLoading"/>
    <v-main v-if="!getIsAppLoading" class="blue-grey lighten-5">
      <speed-dial-add-button />
      <router-view />
    </v-main>

    <v-container class="fill-height text-center" v-else>
      <v-row>
        <v-col cols="12">
          <loading-sogand />
<!--          <v-progress-circular-->
<!--              :size="50"-->
<!--              color="primary"-->
<!--              indeterminate-->
<!--          ></v-progress-circular>-->
        </v-col>
      </v-row>
    </v-container>
<!--    <app-bottom-navigation v-if="!getIsAppLoading"/>-->
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppTopBar from '@/components/global-components/app-top-bar.vue'
import AppBottomNavigation from '@/components/global-components/app-bottom-navigation.vue'
import SpeedDialAddButton from '@/components/global-components/speed-dial-add-button.vue'
import LoadingSogand from '@/components/global-components/loading-sogand.vue'

export default {
  name: 'App',
  components: {
    AppTopBar,
    AppBottomNavigation,
    SpeedDialAddButton,
    LoadingSogand
  },
  mounted() {},
  created() {
    this.$i18n.locale = this.$route.params.countryAbbreviation;
    // this.$i18n.locale = 'fa';
    this.init(this.$route.params.countryAbbreviation);
  },
  methods: {
    ...mapActions([
      'init'
    ])
  },
  computed: {
    ...mapGetters([
      'getIsAppLoading'
    ])
  }
};
</script>

<style lang="sass">
@import "./css/style.scss"
//@font-face
//  font-family: 'Product Sans'
//  font-style: normal
//  font-weight: normal
//  src: local('Product Sans'), url('https://fonts.cdnfonts.com/css/product-sans') format('woff')
//
//body
//  font-family: 'Product Sans', sans-serif

</style>