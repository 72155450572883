import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginIndex from '@/components/login-index.vue';
import RegisterIndex from '@/components/register-index.vue';

import ReportingIndex from '@/components/reporting-index.vue';

import ContactsList from '@/components/contacts/contacts-list.vue';
import ContactsAdd from '@/components/contacts/contacts-add-update.vue';
import ContactsUpdate from '@/components/contacts/contacts-add-update.vue';

import CurrentActionsList from '@/components/current-actions/current-actions-list.vue';
import CurrentActionsAdd from '@/components/current-actions/current-actions-add-update.vue';
import CurrentActionsUpdate from '@/components/current-actions/current-actions-add-update.vue';
import CurrentActionsItem from '@/components/current-actions/current-actions-item.vue';

import DemonstrationsList from "@/components/demonstrations/demonstrations-list.vue";
import DemonstrationItem from "@/components/demonstrations/demonstrations-item.vue";
import DemonstrationsAdd from "@/components/demonstrations/demonstrations-add-update.vue";
import DemonstrationsUpdate from "@/components/demonstrations/demonstrations-add-update.vue";

import MentionsWithTwitterId from "@/components/twitter/mentions-with-twitter-id.vue";

import {getAuth, onAuthStateChanged} from "firebase/auth";
import {auth} from '@/plugins/firebaseInit'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/global/current-actions',
  },
  {
    path: '/:countryAbbreviation',
    redirect: '/:countryAbbreviation/current-actions',
  },
  {
    name: 'CurrentActions',
    path: '/:countryAbbreviation/current-actions',
    component: CurrentActionsList,
  },
  {
    name: 'CurrentActionsAdd',
    path: '/:countryAbbreviation/current-actions/add',
    component: CurrentActionsAdd,
  },
  {
    name: 'CurrentActionsUpdate',
    path: '/:countryAbbreviation/current-actions/update/:id',
    component: CurrentActionsUpdate,
  },
  {
    name: 'CurrentActionsItem',
    path: '/:countryAbbreviation/current-actions/:id',
    component: CurrentActionsItem
  },
  {
    name: 'Reporting',
    path: '/:countryAbbreviation/reporting',
    component: ReportingIndex
  },
  {
    name: 'Demonstrations',
    path: '/:countryAbbreviation/demonstrations',
    component: DemonstrationsList
  },
  {
    name: 'DemonstrationsItem',
    path: '/:countryAbbreviation/demonstrations/:id',
    component: DemonstrationItem
  },
  {
    name: 'DemonstrationsAdd',
    path: '/:countryAbbreviation/demonstrations/add',
    component: DemonstrationsAdd
  },
  {
    name: 'DemonstrationsUpdate',
    path: '/:countryAbbreviation/demonstrations/update/:id',
    component: DemonstrationsUpdate,
  },
  {
    name: 'Contacts',
    path: '/:countryAbbreviation/contacts',
    component: ContactsList
  },
  {
    name: 'ContactsAdd',
    path: '/:countryAbbreviation/contacts/add',
    component: ContactsAdd
  },
  {
    name: 'ContactsUpdate',
    path: '/:countryAbbreviation/contacts/update/:id',
    component: ContactsUpdate
  },
  {
    name: 'Login',
    path: '/:countryAbbreviation/login',
    component: LoginIndex
  },
  // {
  //   name: 'Register',
  //   path: window.location.hostname === 'localhost' ? '/:countryAbbreviation/current-actions' : '/:countryAbbreviation/register',
  //   component: RegisterIndex,
  //   // beforeEnter: (to, from, next) => {
  //   //   window.location.hostname === 'localhost' ? window.open('http://www.google.com') : next();
  //   // },
  // },
  {
    name: 'MentionsWithTwitterId',
    path: '/:countryAbbreviation/mentions-with-twitter-id/:id',
    component: MentionsWithTwitterId
  },

]
// beforeEnter: (to, from, next) => {
//   if (!store.getters.getIsAutenticated) next({ name: 'Login' })
//   else next()
// },

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


let isLoggedIn = false;
onAuthStateChanged(auth, (user) => {
  if (user) isLoggedIn = true;
});

// router.beforeEach((to, from, next) => {
//
//   // instead of having to check every route record with
//   // to.matched.some(record => record.meta.requiresAuth)
//   if (to.meta.requiresAuth && isLoggedIn) {
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     // next()
//   }
// })

export default router
