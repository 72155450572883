<template>
  <v-container fill-height>
    <v-row no-gutters v-if="!getIsAutenticated">
      <v-col cols="12" class="title mb-5 text-center">
        Login
      </v-col>
      <v-col cols="12">
        <v-text-field
            label="Email"
            placeholder="Email"
            outlined hide-details
            class="mb-2"
            v-model="emailModel"

        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
            label="Password"
            placeholder="Password"
            outlined hide-details
            class="mb-5" type="password"
            v-model="passwordModel"
            @keyup.enter="loginClicked()"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn block large depressed color="orange darken--2" dark @click="loginClicked()">Login</v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters v-else>
      <v-col cols="12" class="text-center">
        <v-btn @click="logoutClicked()">LOGOUT</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data () {
    return {
      emailModel: '',
      passwordModel: ''
    }
  },
  computed:{
    ...mapGetters([
        'getIsAutenticated'
    ])
  },
  methods:{
    ...mapActions([
        'login',
        'logout'
    ]),
    loginClicked() {
      this.login({ email: this.emailModel, password: this.passwordModel })
          .then(() => {
            this.$router.push({ name: 'CurrentActions' })
          }).catch(error => {
        console.log(error)
      })
    },
    logoutClicked(){
      this.logout();
    }
  }
}
</script>

<style scoped>

</style>